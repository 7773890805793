/* FOOTER */

.c-footer__wrapper {
}

.c-footer {
  background-color: $dark-500;
  padding-right: $layout-01;
  padding-left: $layout-01;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid $slate-gray;

  @include mq-desktop {
    justify-content: space-between;
  }

  &.is-first {
    padding-top: $layout-02;
    padding-bottom: $layout-02;
  }

  &.is-last {
    padding-top: $layout-01;
    padding-bottom: $layout-01;
  }
}

.c-footer__logo {
  display: flex;
  flex-wrap: wrap;

  @include mq-desktop {
    align-items: center;
  }

  @include mq-small-desktop {
    justify-content: center;
    width: 100%;
    margin-bottom: $space-06;
  }

  @include mq-tablet {
    justify-content: center;
    width: 100%;
    margin-bottom: $space-06;
  }

  @include mq-mobile {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $space-06;
  }

  h6 {
    color: $cream;    
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;

    @include mq-desktop {
      margin-left: $space-04;
    }

    @include mq-small-desktop {
      width: 100%;
      text-align: center;
      margin-top: $space-04;
    }

    @include mq-tablet {
      width: 100%;
      text-align: center;
      margin-top: $space-04;
    }

    @include mq-mobile {
      width: 100%;
      text-align: center;
      margin-top: $space-04;
    }
  }
}

.c-footer__links {
  display: flex;
  justify-content: center;

  @include mq-desktop {
    text-align: left;
  }

  @include mq-small-desktop {
    width: 100%;
    flex-wrap: wrap;
  }

  @include mq-tablet {
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
    
  }

  @include mq-mobile {
    width: 100%;
    text-align: center;
    flex-direction: column;
  }

  li {
    @include mq-desktop {
      margin-right: $space-04;
      margin-left: $space-04;
    }

    @include mq-small-desktop {
      margin-bottom: $space-04;
      margin-right: $space-03;
      margin-left: $space-03;
    }

    @include mq-tablet {
      width: 29%;
    }

    @include mq-mobile {
      margin-bottom: $space-04;
    }

    &:last-child {
      @include mq-mobile {
        margin-bottom: 0;
      }
    }
  }

  a {
    font-size: $font-sm;
    text-decoration: none;
    color: $light-200;
    display: block;

    @include mq-tablet {
      padding-top: $space-03;
      padding-bottom: $space-03;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-footer__social {
  display: flex;
  justify-content: center;

  @include mq-mobile {
    order: 1;
    margin-bottom: $space-04;
    width: 100%;
  }

  a {
    margin-right: $space-04;
    margin-left: $space-04;
    font-size: $font-sm;
    color: $light-200;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-footer__legal {
  font-size: $font-sm;
  color: $light-400;
  line-height: 2rem;

  @include mq-mobile {
    order: 2;
    text-align: center;
    width: 100%;
  }

  a {
    color: $light-200;

    @include mq-desktop {
      margin-right: $space-04;
    }

    @include mq-mobile {
      display: block;
      margin-top: $space-04;
    }
  }
}