.c-event-page {
  display: flex;
  background-color: $slate-gray;
  border-top: 1px solid $dark-300;

  @include mq-mobile {
    flex-wrap: wrap;
  }

  @include mq-tablet {
    flex-wrap: wrap;
  }
}

.c-event-page__banner {
  width: 100%;
  overflow: hidden;
  max-height: 35rem;
  background: $purple url(../../../../assets/img/event-back.png) repeat left top;
  background-size: 8rem; 

  @include mq-desktop {
    min-height: 24rem;
    display: flex;
    justify-content: center;
  }

  img {
    display: block;
    height: 100%;
    max-height: 35rem;
    width: auto;

    @include mq-mobile {
      width: 100%;
      height: auto
    }
  }
}

.c-event-page__background {
  background-size: cover;
  position: absolute;
  width: 110%;
  height: 110%;
  top: 50%;
  left: 50%;
  filter: blur(2rem);
  @include translate(-50%,-50%);
}

.c-event-page__content {
  flex: 1;
  display: flex;

  @include mq-desktop {
    padding: $layout-05;
  }

  @include mq-small-desktop {
    padding: $layout-04;
  }

  @include mq-tablet {
    padding: $layout-03;
    border-bottom: 1px solid $dark-300;
  }

  @include mq-mobile {
    padding: $layout-02 $layout-01;
    text-align: center;
    border-bottom: 1px solid $dark-300;
  }

  &.is-desktop .c-event-page__about {
    @include mq-mobile {
      display: none;
    }

    @include mq-tablet {
      display: none;
    }
  }

  &.is-mobile {
    @include mq-desktop {
      display: none;
    }

    @include mq-tablet {
      display: block;
    }
  }
}

.c-event-page__icon {
  border: 4px solid $pistachio;
  border-radius: $radius-circle;
  overflow: hidden;
  background-color: $white;
  display: flex;
  justify-content: center;
  
  @include mq-desktop {
    flex: 0 0 10rem;
    width: 10rem;
    height: 10rem;
    margin-right: $layout-05;
  }

  @include mq-small-desktop {
    position: absolute;
    top: -4rem;
    width: 8rem;
    height: 8rem;
  }

  @include mq-tablet {
    position: absolute;
    top: -5rem;
    width: 10rem;
    height: 10rem;
    left: 50%;
    @include translateX(-50%);
  }

  @include mq-mobile {
    position: absolute;
    top: -4rem;
    width: 8rem;
    height: 8rem;
    left: 50%;
    @include translateX(-50%);
  }

  img {
    height: 100%;
    min-height: 100%;
  }
}

.c-event-page__description {
  flex: 1;

  @include mq-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.c-event-page__title {
  display: flex;
  flex-wrap: wrap;

  @include mq-desktop {
    margin-bottom: $layout-01;
  }

  @include mq-small-desktop {
    margin-top: $layout-02;
    margin-bottom: $layout-02;
  }

  @include mq-tablet {
    margin-top: $space-08;
    margin-bottom: 0;
    justify-content: center;
    text-align: center;
  }

  @include mq-mobile {
    margin-top: $space-07;
    margin-bottom: 0;
    justify-content: center;
    text-align: center;
  }

  h1 {
    font-weight: 700;
    margin-bottom: 0;
    flex: 0 0 100%;

    @include mq-desktop {
      font-size: $font-xxl;
    }

    @include mq-small-desktop {
      font-size: $font-xxl;
      order: 1;
    }

    @include mq-tablet {
      font-size: $font-xl;
    }

    @include mq-mobile {
      font-size: $font-lg;
    }
  }

  .o-badge {
    width: 6rem;

    @include mq-desktop {
      margin-bottom: $space-03;
    }

    @include mq-small-desktop {
      order: 2;
      margin-top: $space-03;
    }

    @include mq-tablet {
      order: 2;
      margin-top: $space-04;
      margin-bottom: 0;
    }

    @include mq-mobile {
      margin-top: $space-04;
      order: 2;
    }
  }
}

.c-event-page__about {
  color: $light-200;

  @include mq-mobile {
    text-align: left;
  }

  @include mq-tablet {
    text-align: left;
  }

  li {
    list-style-type: disc;
    margin-bottom: $space-03;
  }
}