.c-stickers {
  border: 1px solid $dark-300;
  background: $slate-gray;
  border-radius: $radius-md;
  box-shadow: 0px 5px 10px black;

  @include mq-desktop {
    position: absolute;
    bottom: 5rem;
    right: 0rem;
    width: 38rem;
    z-index: 4;
  }

  @include mq-tablet {
    position: fixed;
    z-index: 7;
    top: auto;
    left: 50%;
    width: 95%;
    @include translate(-50%,-50%);
  }

  @include mq-mobile-landscape {
    position: fixed;
    z-index: 7;
    top: 50%;
    left: 50%;
    width: 95vw;
    height: 90vh;
    @include translate(-50%,-50%);
  }

  @include mq-mobile {
    position: fixed;
    z-index: 7;
    top: 50%;
    left: 50%;
    width: 95%;
    @include translate(-50%,-50%);
  }
}

.c-stickers__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $space-04;
  border-bottom: 1px solid $dark-300;
  width: 100%;
}

.c-stickers--scroll {
  padding: $space-04 $space-04 0;
}

.c-stickers__title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.c-stickers__close {
  display: block;
  color: $white;
  padding: $space-04;

  &:hover {
    color: $pistachio
  }

  .c-icon {
    display: block;
  }
}

.c-stickers__container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-04;

  &.is-last {
    margin-bottom: 0;
  }

  li {
    display: flex;
    align-items: center;

    &:nth-child(7n) {
      a {
        @include mq-desktop {
          margin-right: 0;
        }
      }
    }

    a {
      display: block;
      margin-bottom: $space-04;
      @include animate;

      @include mq-desktop {
        margin-right: $space-04;
      }

      @include mq-tablet {
        margin: $space-04;
      }

      @include mq-mobile {
        margin-right: $space-04;
      }

      &:hover {
        transform: scale(1.4);
      }
    }

    .c-avatar__lock {
      bottom: 0.5rem;
    }
  }
}

.c-stickers__image {
  @include mq-desktop {
    width: 4rem;
  }

  @include mq-tablet {
    width: 4rem;
  }

  @include mq-mobile {
    width: 3.2rem;
  }
}

.c-stickers__overlay {
  @include mq-desktop {
    display: none;
  }

  @include mq-tablet {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 7;
  }

  @include mq-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 7;
  }
}

