/* FORM GLOBAL OBJECTS */

@mixin input-base {
  background: $dark-200;
  color: $white;
  width: 100%;
  border: 1px solid transparent;
  font-size: $font-sm;
  box-shadow: none;
  font-weight: 400;
  border-radius: $radius-sm;
  padding: $space-03 $space-04;
  transition: all .2s ease;
}

::placeholder {color: $light-500;}

label, select {cursor: pointer;}

// select {
//   display: block;
//   height: calc(1rem * 2.5);
//   @mixin input-base;
// }

input[type='text'], input[type='email'], textarea, input[type='date'],
input[type='password'], input[type='search'], input[type='phone'],
input[type='tel'], input[type='number'] {
  @include input-base;
  @include mq-mobile {
    font-size: $font-bs;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    background: $dark-100;
    border-color: $pistachio;
  }
}

.o-field {
  width: 100%;
  margin-bottom: $space-06;

  &:last-child {margin-bottom: 0;}

  &:only-child {margin-bottom: $space-06;}

  label {
    font-weight: 500;
    margin-bottom: $space-02;
    display: block;
  }
}

.o-field__help, .o-field__error {
  display: block;
}

.o-field__help {
  font-style: italic;
  margin-top: $space-02;
  font-size: $font-sm;
  text-align: left;
  color: darken($cream,15%);
}

.o-field__error {
  font-style: italic;
  margin-top: $space-03;
  color: $pale-red;
  font-size: $font-sm;
}

.o-field__half {
  display: flex;
  align-items: center;

  .o-field__input {
    flex: 0 0 8rem;
  }

  h6 {
    flex: 1;
    margin-bottom: 0;
    margin-left: $space-05;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.o-field__actions {
  display: flex;
  justify-content: space-between;
  margin-top: $space-08;
}
