.c-levelup {
  border-radius: $radius-md;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .o-button--outline {
    color: $pistachio;
    
    @include mq-desktop {
      height: 3.2rem;
      padding: $space-03;
    }

    @include mq-mobile {
      height: 2.4rem;
      padding: $space-02 $space-03;
    }

    &:hover {
      background-color: $pistachio;
      color: $slate-gray;
    }
  }

  .c-icon {
    @include mq-mobile {
      display: none
    }
  }
}

.c-levelup__span {
  font-size: $font-xs;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

