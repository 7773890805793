.c-event-card__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $layout-04;
  
  @include mq-mobile {
    margin-top: $layout-04;
    padding-left: $space-04;
    padding-right: $space-04;
    justify-content: space-between;
  }

  @include mq-desktop {
    margin-top: $layout-08;
    padding-left: $layout-01;
    padding-right: $layout-01;
    justify-content: center;
  }

  li {
    @include mq-mobile {
      width: calc(50% - .6rem);
      margin-bottom: $space-04;
    }

    @include mq-desktop {
      margin-right: $space-03;
      margin-bottom: $space-08;
      margin-left: $space-03;
    }

    @include mq-tablet {
      width: calc(50% - 1.6rem);
      margin-bottom: $space-05;
    }
  }

  li:nth-child(2) {
    @include mq-mobile {
      width: 100%;
    }
    .c-event-card {
      @include mq-mobile {
        height: 20rem;
      }
    }
    .c-event-card__image {
      @include mq-mobile {
        height: 20rem;
      }      
    } 
  }

  li:nth-child(3) {
    @include mq-mobile {
      width: 100%;
    }
    .c-event-card {
      @include mq-mobile {
        height: 15rem;
      }
    }
    .c-event-card__image {
      @include mq-mobile {
        height: 15rem;
      }      
    } 
  }
}

.c-event-card__hero {
  flex: 0 0 100%;
  width: 100%;
  text-align: center;  
  margin-bottom: $space-07;
  font-weight: 900;

  @include mq-mobile {
    font-size: $font-lg;
  }

  small {
    display: block;
    font-size: $font-xxs;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: $space-03;
    font-weight: 600;
  } 
}

.c-event-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  border-radius: $radius-md;
  padding-top: $space-04 ;
  padding-right: $space-04;
  padding-left: $space-04;
  text-decoration: none;
  cursor: pointer;

  @include mq-desktop {
    width: 26rem;
    height: 30rem;
    padding-bottom: $space-05;
    @include animate;
  }

  @include mq-tablet {
    width: 100%;
    height: 25rem;
    padding-bottom: $space-05;
  }

  @include mq-mobile {
    padding-bottom: $space-04;
    width: 100%;
    height: 15rem;
  }

  &:hover {
    @include mq-desktop {
      @include translateY(-1rem);
    }

    @include mq-tablet {
      @include translateY(0);
    }
  }
}

.c-event-card__image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;

  @include mq-desktop {
    background-position-x: 10%;
  }

  &:after {
    content: '';
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0,0,0);

    @include mq-mobile {
      background: 
        -webkit-linear-gradient(
          bottom, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.6) 100%
        );
      background: 
        -o-linear-gradient(
          bottom, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.8) 100%
        );
      background: 
        linear-gradient(to top, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.8) 100%
        );
    }

    @include mq-desktop {
      background: 
        -webkit-linear-gradient(
          bottom, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.6) 100%
        );
      background: 
        -o-linear-gradient(
          bottom, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.8) 100%
        );
      background: 
        linear-gradient(to top, 
          rgba(0,0,0,0.9) 5%, 
          rgba(0,0,0,0) 40%,
          rgba(0,0,0,0) 70%, 
          rgba(0,0,0,0.8) 100%
        );
    }
  }
}

.c-event-card__time {
  z-index: 2;
  display: flex;
  justify-content: space-between;

  span {
    font-family: $font-aktiv-ext;
    font-weight: 800;
    color: $cream;
    text-transform: uppercase;

    @include mq-mobile {
      font-size: $font-xs;
      letter-spacing: 1px;
    }

    @include mq-desktop {
      font-size: $font-sm;
      letter-spacing: 2px;
    }
  }
}

.c-event-card__content {
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  color: $white;

  .o-badge{
    @include mq-mobile {
      padding-right: $space-02;
      padding-left: $space-02;
    }
  }

  .o-badge__live {
    @include mq-mobile {
      width: .4rem;
      height: .4rem;
    }
  }
  .o-badge__text {
    @include mq-mobile {
      font-size: $font-xxs;
      line-height: 1.2;
    }
  }
}

.c-event-card__title {
  width: 100%;
  margin-top: $space-03;
  margin-bottom: 0;
  font-weight: 500;

  @include mq-mobile {
    font-size: $font-sm;
  }
}
