.c-stripe {
  display: flex;

  @include mq-desktop {
    flex-wrap: wrap; 
    height: 70vh; 
  }

  @include mq-tablet {
    height: 80vh;
  }

  @include mq-mobile-landscape {
    flex-wrap: wrap; 
    height: 55vh !important;
    -webkit-overflow-scrolling: touch;
  }

  @include mq-mobile {
    flex-wrap: wrap;  
    height: 70vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.c-stripe__wrapper {
  @include mq-desktop {
    flex: 0 0 55%;
    height: 100%;
    border-right: 1px solid $dark-300;
    overflow-y: auto;
    padding: $space-06;
    overflow-y: auto;
  }

  @include mq-tablet {
    flex: 0 0 55%;
    height: 100%;
    overflow-y: auto;
  }

  @include mq-mobile {
    flex: 0 0 100%;
    order: 2;
    padding: $space-05 $space-04;
  }

  label {
    font-size: $font-sm;
  }

  select {
    width: 100%;
    padding: $space-02 $space-02;
    background: $dark-200;
    color: $white;
    border: 1px solid transparent;
    border-radius: $radius-sm;
    font-weight: 400;

    @include mq-mobile {
      font-size: $font-bs;
    }
  }

  .o-field--third {
    width: 35%;
  }
}

.c-stripe__title {
  color: $cream;
}

.c-stripe__error {
  flex: 0 0 100%;
  background-color: $pale-red;
  font-size: $font-sm;

  @include mq-desktop {
    padding: $space-03 $space-06;
  }

  @include mq-mobile {
    padding: $space-03 $space-04;
  }
  
}

.c-stripe__order {
  @include mq-desktop {
    padding: $space-06;
    flex: 1;
  }

  @include mq-tablet {
    flex: 0 0 45%;
  }

  @include mq-mobile {
    flex: 0 0 100%;
    order: 1;
    padding: $space-05 $space-04;
  }
}

.c-order {  
  @include mq-mobile {
    background-color: $dark-200;
    padding: $space-05 $space-04;
    border-radius: $radius-sm;
  }

  .c-upgrade__currency {
    li {
      flex: 0 0 100%;
      justify-content: flex-end;
    }
  }
}

.c-order__title {
  margin-bottom: $space-03;
  color: $cream;
}

.c-order__single {
  display: flex;
  align-items: center;

  &:nth-child(2) {
    border-bottom: 1px solid $dark-300;

    span {
      padding-bottom: $space-04;
    }
  }

  span {
    padding-top: $space-04;
    font-size: $font-sm;

    &.is-left {
      flex: 1;
    }

    &.is-right {
      flex: 0 0 10rem;
      min-width: 8rem;
      text-align: right;
    }
  }
}

.c-order__icon {
  width: 1.9rem;
  height: 1.9rem;
  border: 1px solid $pale-red;
  border-radius: $radius-circle;
  overflow: hidden;
  margin-right: $space-02;

  img {
    width: 100%;
    display: block;
  }
}

.c-order__total {
  color: $pistachio;
  font-weight: bold;
}



.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-element {
  border-radius: $radius-sm;
  padding: $space-03 $space-04;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
.stripe-submit {
  display: block;
  width: 100%;
  transition: all 0.2s ease;
}

.stripe-submit:disabled {
  opacity: 0.5;
  cursor: default;
}

