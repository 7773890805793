.c-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-events {
  padding-top: $space-07;
  padding-bottom: $space-07;

  .c-loading__container {
    @include mq-mobile {
      height: 100%;
    }
    
    @include mq-desktop {
      height: 50vh;
    }

    @include mq-tablet {
      height: 100%;
    }
  }
}

.c-events__info {
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-right: $space-05;
  padding-left: $space-05;

  .c-icon {
    color: $pale-red;
  }
}

.c-events__title {
  margin-top: $space-02;
  margin-bottom: $layout-05;
  width: 100%;
  
  h1{
    font-weight: 900;
    line-height: 1.6;
    margin-bottom: 0;
    color: $cream;

    @include mq-desktop {
      font-size: $font-xxl;
    }

    @include mq-mobile {
      font-size: $font-xl;
    }  
  }
  
}

.c-events__container {
  padding-top: $layout-07;
  flex: 1;

  @include mq-mobile {
    width: 100%;
  }
}

.c-events__carousel {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;

  li {
    margin-bottom: $space-05;

    @include mq-desktop {
      padding-right: $space-03;
      padding-left: $space-03;
    }

    @include mq-tablet {
      width: calc((100% / 2) - 3.2rem);
      padding-right: $space-05;
      padding-left: $space-05;
    }

    @include mq-mobile {
      width: 100%;
      padding-right: $space-05;
      padding-left: $space-05;
    }

    &:nth-child(odd) {
      @include mq-tablet {
        margin-right: 0;
      }
    }
  }
}
