.c-chat__message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: $space-03;
  width: 100%;
  min-height: 0;

  &.is-admin,
  &.is-guest,
  &.is-top-fan {
    justify-content: flex-start;

    .c-chat__avatar {
      order: 1;
      margin-right: $space-03;
    }

    .c-chat__message {
      order: 2;
    }
  }

  &.is-admin,
  &.is-top-fan {
    .c-chat__message {
      padding-top: $space-03;
    }
  }

  &.is-admin {
    .c-chat__avatar {
      background-color: $pistachio;
    }
  }

  &.is-top-fan {
    .c-chat__avatar {
      background-color: $pale-red;
    }
  }

  &.is-guest,
  &.is-me {
    .c-chat__avatar {
      background-color: lighten($purple, 15%);
    }
  }

  &.is-me {
    justify-content: flex-end;

    .c-chat__avatar {
      order: 2;
      margin-left: $space-03;
    }
  }
}

.c-chat__avatar {
  border-radius: $radius-circle;
  overflow: hidden;
  width: 3.2rem;
  height: 3.2rem;
  flex: 0 0 3.2rem;

  img {
    width: 100%;
  }
}

.c-chat__badge {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: top;
  margin-right: $space-02;
  border-radius: $radius-circle;

  &.is-top-fan {
    background-color: $purple;

    .c-icon {
      color: $pistachio;
    }
  }

  .c-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-chat__message {
  margin-top: $space-02;
  padding: $space-02;
  font-size: $font-sm;
  line-height: 1.25;
  font-weight: 500;
  background-color: $dark-300;
  border-radius: $radius-md;
  word-break: break-word;
  max-width: 40rem;

  &.is-admin,
  &.is-top-fan {
    border-top-left-radius: 0;
    margin-right: $space-06;

    &:before {
      position: absolute;
      top: -0.4rem;
      left: 0rem;
      font-size: $font-xxs;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 1px $space-02 0;
      border-radius: $radius-sm;
      border-bottom-left-radius: 0;
    }
  }

  &.is-admin {
    &:before {
      content: "Admin";
      background-color: $pistachio;
      color: $slate-gray;
    }
  }

  &.is-top-fan {
    background-color: lighten($purple, 10%);

    &:before {
      content: "Top Fan";
      background-color: $pale-red;
      color: $white;
    }
  }

  &.is-me {
    background-color: $dark-400;
    border-bottom-right-radius: 0;
    margin-left: $space-06;
  }

  &.is-guest {
    background-color: $dark-400;
    border-top-left-radius: 0;
    margin-right: $space-06;
  }

  &.is-alert {
    margin-top: 0;
    background-color: $pistachio;
    border-top-left-radius: 0;
    margin-right: $space-06;
    color: $dark-500;
  }

  img {
    display: block;
    margin-top: $space-02;
    margin-left: auto;
    margin-right: auto;
  }

  small {
    font-size: $font-xs;
    color: $light-400;
  }
}

.c-chat__author {
  font-weight: 700;
  font-size: $font-sm;
  margin-right: $space-02;

  small {
    font-weight: 500;
  }
}
