.c-banned__wrapper {
  background-color: $dark-500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c-banned {
  width: 90%;
  text-align: center;
  
  @include mq-mobile {
    max-width: 40rem;
  }

  @include mq-desktop {
    max-width: 70rem;
  }

  @include mq-tablet {
    max-width: 62rem;
  }

  span {

    @include mq-mobile {
      font-size: $font-X;
    }

    @include mq-desktop {
      font-size: $font-XXX;
    }
  }

  h1 {
    @include mq-mobile {
      font-size: $font-lg;
    }
  }

  p {
    color: $light-500;

    @include mq-desktop {
      font-size: $font-md;
    }
  }

  .o-button--green {
    max-width: 30rem;
    width: 100%;
    display: inline-block;
    margin-top: $layout-03;
  }
  
}