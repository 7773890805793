.c-stories__hero {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $purple;

  @include mq-desktop {
    margin-top: $layout-07;
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    margin-top: $layout-04;
    padding-top: $layout-09;
    padding-bottom: $layout-09;
  }
}

.c-stories-hero__wrapper {
  text-align: center;

  @include mq-desktop {
    max-width: 70rem;
  }

  @include mq-mobile {
    padding-right: $space-04;
    padding-left: $space-04;
  }
}

.c-stories__category {
  color: $pale-red;
}

.c-stories-hero__title {
  font-weight: 400;

  @include mq-desktop {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-stories__title {
  margin-bottom: 0;

  @include mq-desktop {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-stories__text {
  @include mq-desktop {
    font-size: $font-lg;
  }

  @include mq-mobile {
    font-size: $font-md;
  }
}

.c-stories__challenge {
  background: $light-200;
  color: $slate-gray;
  padding-right: $space-05;
  padding-left: $space-05;
  display: flex;
  justify-content: center;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11 * 2;
  }

  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-stories-challenge__wrapper {
  max-width: 70rem;
  display: flex;
  flex-wrap: wrap;
}

.c-stories-results {
  background-color: $purple;
  background-image: url(https://boletia-v2.s3.amazonaws.com/uploads/neerme/neermegraph.png);
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-right: $space-05;
  padding-left: $space-05;
  display: flex;
  flex-direction: column;
  
  @include mq-desktop {
    background-size: 100%;
    padding-top: $layout-11;
    padding-bottom: $layout-11;
    align-items: center;
  }

  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-stories-results__title {
  max-width: 100rem;
  width: 100%;

  @include mq-mobile {
    margin-bottom: $space-06;
  }

  h1, p {
    max-width: 40rem;
    margin-bottom: 0;
  }
}

.c-stories-results__wrapper {
  width: 100%;

  @include mq-desktop {
    justify-content: center;
    display: flex;
  }

  @include mq-tablet {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.c-stories-results__single {
  padding-top: $space-05;

  @include mq-desktop {
    padding-right: $space-05;
    padding-bottom: $space-05;
    padding-left: $space-05;
  }

  @include mq-tablet {
    padding-right: $space-05;
    padding-bottom: $space-05;
    padding-left: $space-05;
  }

  @include mq-mobile {
    width: 100%;
  }
 
  &.is-first {
    align-self: flex-end;
  }

  &.is-middle {
    align-self: center;
  }

  &.is-last {
    align-self: flex-start;
  }

  h1 {
    margin-bottom: 0;
    color: $pistachio;

    @include mq-mobile {
      font-size: $font-lg;
    }
  }
}


.c-stories-results.is-comedy {
  background-image: url(https://boletia-v2.s3.amazonaws.com/uploads/neerme/neermegraph2.png);
  background-size: 100%;

  @include mq-desktop {
    align-items: center;
    background-position-x: 3rem;
  }

  .c-stories-results__wrapper {
    max-width: 120rem;
    
    @include mq-desktop {
      height: 53rem;
    }

    @include mq-tablet {
      height: 50rem;
    }

    @include mq-mobile {
      height: 45rem;
    }
  }

  .c-stories-results__single {
    @include mq-desktop {
      width: calc(100% / 4);
    }

    @include mq-tablet {
      width: calc(100% / 4);
    }

    @include mq-mobile {
      width: 100%;
      max-width: 36rem;
    }

    &.is-middle-1 ,&.is-middle-2 {
      align-self: center;
    }

    &.is-middle-1 {
      @include mq-desktop {
        margin-top: 13rem;
      } 
    }

    &.is-middle-2 {
      @include mq-desktop {
        margin-top: -13rem;
      } 
    }
  }

  .c-stories-results__title {
    max-width: 120rem;
  }
}


.c-stories-results.is-digital {  
  @include mq-desktop {
    background-position-x: 12rem;
  }

  @include mq-small-desktop {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-tablet {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-mobile {
    background-position-x: 0;
    background-size: 120%;
  }

  .c-stories-results__wrapper {
    max-width: 80rem;

    @include mq-desktop {
      height: 45rem;
    }

    @include mq-tablet {
      height: 40rem;
    }

    @include mq-mobile {
      height: 25rem;
    }
  }

  .c-stories-results__single {
    @include mq-desktop {
      width: calc(100% / 2);
    }

    @include mq-tablet {
      width: calc(100% / 2);
    }

    @include mq-mobile {
      max-width: 36rem;
    }
  }

  .c-stories-results__title {
    max-width: 80rem;

    @include mq-desktop {
      margin-bottom: $layout-06;
    }
  }
}

.c-stories-results.is-theater {  
  @include mq-desktop {
    background-position-x: 12rem;
  }

  @include mq-small-desktop {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-tablet {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-mobile {
    background-position-x: 0;
    background-size: 120%;
  }

  .c-stories-results__wrapper {
    max-width: 65rem;

    @include mq-desktop {
      height: 32rem;
    }

    @include mq-tablet {
      height: 28rem;
    }

    @include mq-mobile {
      height: 25rem;
    }
  }

  .c-stories-results__single {
    @include mq-desktop {
      width: calc(100% / 2);
    }

    @include mq-tablet {
      width: calc(100% / 2);
    }

    @include mq-mobile {
      max-width: 36rem;
    }
  }

  .c-stories-results__title {
    max-width: 80rem;

    @include mq-desktop {
      margin-bottom: $layout-08;
    }
  }
}

.c-stories-results.is-music {  
  @include mq-desktop {
    background-position-x: 12rem;
  }

  @include mq-small-desktop {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-tablet {
    background-position-x: 0;
    background-size: 130%;
  }

  @include mq-mobile {
    background-position-x: 0;
    background-size: 120%;
  }

  .c-stories-results__title {
    max-width: 80rem;

    @include mq-desktop {
      margin-bottom: $layout-11 * 2;
    }
  }
}

.c-stories-magnet {
  background-color: $dark-500;
}