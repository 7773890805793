.o-badge__container {
  display: flex;
}

.o-badge {
  display: flex;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: $radius-sm;
  font-weight: 500;
  padding: $space-01 $space-03;
  align-items: center;
  color: $white;

  &:last-child {
    margin-left: $space-03;
  }

  &.is-link {
    color: $light-400;
    text-decoration: none;
    padding: 0;

    &:hover {
      color: $light-100;
    }
  }
  &.is-live {
    background-color: $pale-red;
  }
  &.is-visitors {
    background-color: $dark-100;
  }

  span {
    font-size: $font-xs;
    line-height: 2rem;
  }

  .c-icon {
    margin-right: $space-03;
  }
}

.o-badge__live {
  width: .8rem;
  height: .8rem;
  margin-right: $space-02;
  margin-top: -1px;
  background-color: $white;
  border-radius: $radius-circle;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {opacity: .5;}
    50% {opacity: 1;}
    100% {opacity: .5;}
  }
}
