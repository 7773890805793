@keyframes fadeInComponent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutComponent {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
