@mixin block {
  border-radius: $radius-md;
  padding: $space-05 $space-04;
}

.c-poll__single {
  background: $dark-300;
  display: block;
  margin-bottom: $space-05;
  word-break: break-word;
  @include block;
  @include animate;

  .c-icon {
    transform: rotate(-90deg);
    position: absolute;
    top: $space-05;
    right: $space-05;
    color: $light-500;
    @include animate;
  }
}

.c-poll__checkbox {
  display: none;

  &:checked {
    & + .c-poll__single .c-poll__dropdown {
      height: auto;
    }

    & + .c-poll__single .c-icon {
      transform: rotate(90deg);
    }
  } 
}

.c-poll__dropdown {
  height: 0;
  overflow: hidden;
  @include animate;
}

/* FIRST BLOCK */

.c-poll__header {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: $space-04;
  font-size: $font-xxs;
  color: $light-300;
}

.c-poll__title {
  margin-bottom: $space-02;
  margin-right: $space-06;
}

.c-poll__button {
  margin-top: $space-07;
  text-align: center;
}

.c-poll__current {
  background: $purple;
  color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include block;

  .c-poll__title {
    color: $pistachio;
  }

  .c-poll__button {
    @include mq-mobile {
      margin-top: $space-08;
    }
  }

  .c-poll__summary {
    border-bottom-color: darken($purple,5%);
  }
}

.c-poll__flex {
  flex: 1;
}

.c-poll__winner {
  font-weight: 400;
  color: $pistachio;
  margin-bottom: 0;
}

/* SUMMARY */

.c-poll__summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $space-05;
  margin-top: $space-05;
  margin-bottom: $space-05;
  border-bottom: 1px solid $dark-400;
  color: $cream;

  h5 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: $font-xxs;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0;
  }
}

.c-poll__data {
  flex:0 0 49%;
}

/* DATA */

.c-poll__stats {
  margin-bottom: $space-04;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  .is-winner {
    color: $pistachio;
  }

  span {
    flex: 1;
    font-size: $font-sm;
    font-family: $font-proxima;
  }

  h4 {
    text-align: right;
    flex: 0 0 6rem;
    margin-bottom: 0;
    margin-left: $space-02;
  }
}
