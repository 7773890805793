#root {
  height: 100%;
}

.c-login__container {
  display: flex;
  flex-direction: row;
  background-color: $purple;
  background: url("../../../../assets/img/background.png") repeat top left;
  background-size: 40%;

  @include mq-desktop {
    height: 100vh;
  }

  @include mq-tablet {
    height: 100%;
  }
}

.c-login {
  z-index: 2;

  @include mq-mobile {
    width: 100%;
    padding: $layout-02;
    background: rgba($dark-500, 0.85);
    height: 100vh;
  }

  @include mq-desktop {
    width: 40rem;
    padding: $layout-02 $layout-04;
    background: rgba($dark-500, 0.7);
    display: flex;
    flex-direction: column;
  }

  .is-neerme-logo {
    height: auto;
    flex: 0 0 1.5rem;
  }

  .o-button--lg {
    margin-top: $space-08;
  }

  h3 {
    font-weight: 400;
  }
}

.c-login__form {
  flex: 1;
}

.c-login__title {
  margin-top: $space-08;
  margin-bottom: $space-07;
  font-weight: 700;
}

.c-login__field {
  margin-bottom: $space-06;
}

.c-login__label {
  font-size: $font-xxs;
  color: $light-200;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.c-login__help {
  display: block;
  color: $light-200;
  margin-top: $layout-07;
  font-size: $font-xs;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.c-login__info {
  font-size: $font-md;
}

.c-login__powered {
  flex: 0 0 3rem;
  display: flex;
  align-items: center;
  margin-top: $layout-04;

  span {
    font-size: $font-sm;
  }

  a {
    flex: 0 0 8rem;
    margin-left: $space-03;

    .c-icon {
      display: block;
    }
  }
}

.c-login__message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: $layout-02;
  padding: $layout-07;
  max-width: 70%;

  @include mq-tablet {
    display: none;
  }

  @include mq-mobile {
    display: none;
  }

  h1 {
    font-family: $font-aktiv-ext;
    font-size: $font-XXX;
    font-weight: 900;
    color: $pale-red;
  }

  small {
    font-family: $font-proxima;
    font-weight: 400;
    font-size: $font-md;
    font-style: italic;
    display: flex;
    align-items: center;
    color: $cream;
  }

  .c-chat__avatar {
    display: inline-block;
    margin-left: $space-03;
    margin-right: $space-03;
    background-color: $pistachio;
  }
}

.c-login__events {
  overflow-y: scroll;
  padding: $layout-05;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;

  h2 {
    width: 100%;
    text-align: center;
  }

  @include mq-mobile {
    display: none;
  }

  li {
    margin:$space-03;

    @include mq-tablet {
      margin-right: 0;
      margin-left: 0;
    }
  }
}