/* LIVE STREAM */

.c-livestream__container {
  display: flex;
  color: $white;

  @include mq-desktop {
    height: 100vh;
    align-items: stretch;
    padding-top: $layout-05;
  }

  @include mq-tablet {
    padding-top: $layout-05;
    flex-direction: column;
  }

  @include mq-mobile {
    padding-top: $layout-04;
    flex-direction: column;
  }
}

.c-livestream {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq-mobile {
    height: 100%;
  }

  @include mq-desktop {
    flex: 1;
  }

  @include mq-tablet {
    height: auto;
    display: block;
  }
}

.c-livestream__links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 1;

  @include mq-desktop {
    padding: $space-04;
  } 

  @include mq-mobile {
    padding: $space-03;
    justify-content: flex-end;
    position: absolute;
    z-index: 4;
  } 

  .is-link {
    @include mq-mobile {
      display: none;
    }
  }
}

.c-livestream__info {
  display: flex;
  align-items: center;

  @include mq-desktop {
    padding: $space-05 $space-04;
  }

  @include mq-tablet {
    border-top: 1px solid $dark-400;
    border-bottom: 1px solid $dark-400;
    padding: $space-04;
  }

  @include mq-mobile {
    border-top: 1px solid $dark-400;
    border-bottom: 1px solid $dark-400;
    padding: $space-04;
  }

  // .o-button--green {
  //   flex: 0 0 15rem;
  // }
}

.c-livestream__event {
  display: flex;
  flex: 1;
  align-items: center;

  @include mq-mobile {
    flex-wrap: wrap;
  }
}

.c-livestream__logo {
  background-color: $dark-400;
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
  border-radius: $radius-circle;
  display: flex;
  margin-right: $space-04;
  overflow: hidden;

  @include mq-tablet {
    display: flex;
  }

  @include mq-mobile {
    display: none;
  }

  img {
    width: 100%;
    display: block;
    border-radius: $radius-circle;
  }
}

.c-livestream__name {
  margin: 0;
  font-weight: 500;
  flex: 1;

  @include mq-mobile {
    font-size: $font-bs;
  }
}

.c-livestream__ad {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq-desktop {
    margin-left: $space-05;
  }

  @include mq-tablet {
    margin-left: $space-05;
  }

  @include mq-mobile {
    flex: 0 0 100%;
    margin-top: $space-05;
    justify-content: space-around;
  }

  a, img {display: block}

  img {
    @include mq-desktop {
      height: 4.2rem;
    }

    @include mq-tablet {
      height: 4.2rem;
    }

    @include mq-mobile {
      height: 4rem;
    }
  }

  a {
    @include mq-desktop {
      margin-left: $space-03;
    }

    &:first-child {
      @include mq-desktop {
        margin-left: 0;
      }
    }
  }
}

.c-livestream__sidebar {
  @include mq-desktop {
    flex: 0 0 35rem;
    display: flex;
    flex-direction: column;
  }

  @include mq-tablet {
    display: block;
    flex: 0 0 30rem;
  }

  @include mq-mobile-landscape {
    flex: 0 0 30rem;
  }

  &.is-right {
    @include mq-desktop {
      border-left: 1px solid $dark-400;
    }

    @include mq-tablet {
      border-left: none;
    }

    @include mq-mobile {
      border-left: none;
    }
  }

  &.is-left {
    @include mq-desktop {
      border-right: 1px solid $dark-400;
    }

    @include mq-tablet {
      border-right: none;
    }

    @include mq-mobile {
      border-right: none;
    }
  }
}

.c-livestream__header {
  border-bottom: 1px solid $dark-400;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq-desktop {
    padding: $space-04;
    height: 4.8rem;
    flex: 0 0 4.8rem;
  }

  @include mq-tablet {
    padding: $space-03 $space-04 $space-03;
    height: 4rem;
    flex: 0 0 4rem;
  }

  @include mq-mobile {
    padding: $space-03 $space-04 $space-03;
    height: 4rem;
    flex: 0 0 4rem;
  }

  h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    color: $light-200;
  }

  .o-button--sm {
    @include mq-desktop {
      display: none;
    }

    @include mq-tablet {
      display: inline-block;
    }
  }
}

.c-livestream--admin {
  .c-livestream {
    @include mq-mobile {
      order: 1;
    }
    @include mq-tablet {
      order: 1;
    }
  }

  .c-livestream__sidebar.is-left {
    @include mq-mobile {
      order: 2;
    }
    @include mq-tablet {
      order: 2;
    }
  }

  .c-livestream__sidebar.is-right {
    @include mq-mobile {
      order: 3;
    }
    @include mq-tablet {
      order: 3;
    }
  }
}

.c-livestream__button {
  display: flex;
  align-items: center;
  padding: $space-03;
  border-radius: $radius-sm;
  color: $light-100;

  &:hover {
    background-color: $slate-gray;
    color: $pistachio;
  }

  &.is-hidden {
    width: 3.2rem;
    visibility: hidden;
  }
}

.c-livestream__alert {
  bottom: 5rem;
  left: 1.2rem;
  position: absolute;
  z-index: 4;
  display: flex;
  background: $pistachio;
  color: $slate-gray;
  justify-content: space-between;
  border-radius: $radius-md;

  @include mq-desktop {
    width:50%;
    max-width: 50rem;
  }

  @include mq-mobile {
    width: calc(100% - 2.4rem);
    max-width: 45rem;
  }

  &:before {
    content: "";
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid $pistachio;
    position: absolute;
    bottom: -1rem;
    left: 4rem;
  }

  span {
    font-size: $font-sm;
    padding: $space-03 $space-04;

    .c-icon {
      margin-left: $space-02;
      margin-right: $space-02;
      top: 2px;
    }
  }

  &.is-mobile {
    position: fixed;
    z-index: 3;

    @include mq-desktop {
      display: none;
    }

    span .c-icon {
      vertical-align: text-top;
    }
  }
}

.c-livestream__alert-exit {
  color: $slate-gray;
  padding: $space-04;
  line-height: 0;
}

.c-waiting__container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: url("../../../../assets/img/backgroundgray.png") repeat center;
  background-size: 60%;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.c-waiting {
  margin-right: $layout-03;
  margin-left: $layout-03;
  max-width: 50rem;
  text-align: center;
  z-index: 2;
  padding: $layout-05 $layout-08;
  background-color: $purple;
  border-radius: $radius-md;
  
  h2 {
    margin-top: $space-05;
    font-weight: 700;
  }
}

.c-livestream__tilead {
  width: 100%;
  height: 150px;
  display: flex;
  background-color: white;

  .c-livestream__tilead-column-1 {
    height: 100%;
    flex-basis: 60%;
    display: flex;
    flex-wrap: wrap;
  }

  .c-livestream__tilead-column-2 {
    height: 100%;
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #575760;
    a {
      font-size: 50%;
      line-height: 50%;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .c-livestream__tilead-item {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #575760;

    a {
      font-size: 50%;
      line-height: 50%;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.size-1 {
      width: 33.3%;
    }

    &.size-2 {
      width: 66.6%;
    }
  }
}

.c-sidebar__ads {
  .c-sidebar-item {
    width: 100%;
    flex-grow: 1;
    background-color: orange;
    border: 1px solid #575760;
    a {
      font-size: 50%;
      line-height: 50%;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
