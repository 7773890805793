.c-livepoll--desktop {
  flex-direction: column;
  flex: 0 0 40vh;

  @include mq-mobile {
    display: none;
  }

  @include mq-desktop {
    display: flex;
  }

  @include mq-tablet {
    display: none;
  }
}

.c-livepoll--mobile {
  width: 100%;

  @include mq-mobile {
    display: block;
  }

  @include mq-desktop {
    display: none;
  }

  @include mq-tablet {
    display: block;
    text-align: center;
  }
}
.c-livepoll--nochat {
  z-index: 99;
  width: 100%;
  
  @include mq-desktop {
    position: fixed;
    width: 22em;
    right: 0;
    margin: 1em;
    bottom: 0;
  }
  @include mq-mobile {
    position: relative;
  }

}

.c-livepoll {
  background-color: $purple;
  padding: $space-05 $space-04;
}

.c-livepoll {
  .c-poll__header {
    margin-bottom: $space-02;
  }
}

.c-livepoll__exit {
  position: absolute;
  padding: $space-04;
  top: 0;
  right: 0;
  color: $white
}

.c-livepoll__list {
  margin-top: $space-04;
  margin-bottom: 0;
  padding-left: 0;

  @include mq-tablet {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  li {
    counter-increment: my-counter;
    margin-top: $space-03;
    margin-bottom: $space-03;

    @include mq-tablet {
      margin-top: 0;
      margin-right: $space-03;
      width: (97.8%)/3;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:nth-child(3) {
      @include mq-tablet {
        margin-right: 0;
      }
    }

    &:before {
      content: counter(my-counter);
      color: $white;
      font-size: $font-xs;
      line-height: 1.8rem;
      font-weight: bold;
      position: absolute;
      left: -$space-02;
      top: -$space-02;
      width: 1.8rem;
      height: 1.8rem;
      background: $pale-red;
      border-radius: $radius-circle;
      text-align: center;
      z-index: 1;
    }
  }
}

.c-livepoll__button {
  display: block;
  width: 100%;
  height: 100%;
  padding: $space-03 $space-04;
}

.c-upgrade__modal {
  .c-livepoll {
    max-width: 85rem;
    width: 95%;
    
  }
}

.c-livepoll__list.is-modal {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: $space-05;

  @include mq-tablet {
    overflow: auto;
    height: 78vh;
  }

  @include mq-mobile {
    overflow: auto;
    height: 78vh;
  }

  @include mq-mobile-landscape {
    overflow: auto;
    height: 68vh;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 $space-03;

    @include mq-desktop {
      width: calc(100%/4);
    }
    @include mq-tablet {
      width: calc(100%/3);
    }
    @include mq-mobile {
      width: calc(100%/2);
    }

    &:before {
      top: 50%;
      left: 1.2rem;
      @include translateY(-50%);
    }

    a {
      margin: $space-02;
      text-align: left;
      justify-content: flex-start;
      padding-left: $space-07;
      border-radius: $radius-sm;

      @include mq-mobile {
        font-size: $font-xs;
      }
    }
  }
}