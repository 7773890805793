@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number;
    }
  }

  @return $value;
}


/*** VARIABLES ***/

$font-aktiv-ext: aktiv-grotesk-extended, Helvetica, sans-serif;
$font-proxima: proxima-nova, Helvetica, sans-serif;

$font-base: 1.6rem;
$font-ratio: 1.2;

/* FONT SIZE */
$font-xxs: pow($font-ratio, -3) * $font-base;
$font-xs: pow($font-ratio, -2) * $font-base;
$font-sm: pow($font-ratio, -1) * $font-base;
$font-bs: pow($font-ratio, 0) * $font-base;
$font-md: pow($font-ratio, 1) * $font-base;
$font-lg: pow($font-ratio, 2) * $font-base;
$font-xl: pow($font-ratio, 3) * $font-base;
$font-xxl: pow($font-ratio, 4) * $font-base;
$font-X: pow($font-ratio, 5) * $font-base;
$font-XX: pow($font-ratio, 6) * $font-base;
$font-XXX: pow($font-ratio, 7) * $font-base;

/* LINE HEIGHT*/
$XXX-line-height: 1.2;
$heading-line-height: 1.3;
$body-line-height: 1.5;


html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-proxima;
  font-size: $font-bs;
  line-height: $body-line-height
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-aktiv-ext;
  line-height: $heading-line-height;
}

h1 {font-size: $font-xl;}
h2 {font-size: $font-lg;}
h3 {font-size: $font-md;}
h4 {font-size: $font-bs;}
h5 {font-size: $font-sm;}
h6 {font-size: $font-xs;}

p, li, label, input, textarea, select {
  font-size: $font-bs;
  line-height: $body-line-height;
}

button, label {
  font-family: $font-aktiv-ext;
}

input, button, textarea, label, select {
  font-display: swap;
}

