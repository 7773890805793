.app_plugin_rebus * {
  position: static;
  font-family: $font-proxima;

  input {
    background-color: rgb(242, 242, 242);
    color: rgb(54, 53, 55);

    &:focus {
      background-color: rgb(242, 242, 242);
      color: rgb(54, 53, 55);
    }
  }

  .fXRYhk .fvvtSE {
    padding: 0px 1rem 0px 3.2rem;
  }

  .sc-kAzzGY.cAHFvr,
  .sc-gqjmRU {
    display: none!important;
  }

  .llBaNr {
    max-height: 100% !important;
    overflow: auto;
  }

  .hGmAnD {
    max-height: 100vh;
  }

  .hLAFwy {
    margin-bottom: 1rem;
  }
}