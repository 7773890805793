.c-notification__wrapper {
  padding: $space-03 $space-04;
  display: flex;
  align-items: center;
  width: 100%;

  &.is-warning {
    background-color: $yellow;
    color: $slate-gray;
    height: 3.2rem;
  }

  &.is-success {
    background-color: $pistachio;
    color: $slate-gray;
    height: 3.2rem;
  }

  &.is-error {
    background-color: $pale-red;
    color: $white;
    flex-direction: column;

    .c-notification {
      margin-bottom: $space-03;
    }
  }
}

.c-notification {
  display: flex;
  flex: 1;
  align-items: center;
}

.c-notification__icon {
  margin-right: $space-02;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
}

.c-notification__text {
  font-size: $font-sm;
  font-weight: 600;
  line-height: 1.25;
}

.c-notification__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: $space-03;
  color: $white;
}

#mailgo .m-by {
  display: none;
}