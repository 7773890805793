.c-landing {
  background-color: $dark-500;
  min-height: 100vh;
  margin: 0;

  @include mq-mobile {
    overflow-x: hidden;
  }

  .o-button--lg {
    display: inline-block;
  }

  [data-slideleft].visible, [data-slideleft].invisible,
  [data-slideright].visible, [data-slideright].invisible ,
  [data-slidebottom].visible, [data-slidebottom].invisible {
    @include mq-desktop {
      opacity: 1.0;
      transform: translate(0, 0);
      transition: transform 0.9s cubic-bezier(.31,.96,.49,.98), opacity 0.2s ease;
    }
  }

  [data-slideleft].invisible {
    @include mq-desktop {
      opacity: 0.0;
      transform: translate(10rem, 0);
    }
  }

  [data-slideright].invisible {
    @include mq-desktop {
      opacity: 0.0;
      transform: translate(-10rem, 0);
    }
  }

  [data-slidebottom].invisible {
    @include mq-desktop {
      opacity: 0.0;
      transform: translate(0, 5rem);
    }
  }
}

.c-landing-hero {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mq-desktop {
    padding-top: 12rem;
  }
  @include mq-mobile {
    padding-top: 8rem;
  }
}

.c-landing-hero__wrapper {
  width: 100%;
  text-align: center;
  padding-right: $space-04;
  padding-left: $space-04;

  @include mq-desktop {
    margin-bottom: $layout-07;
  }

  @include mq-mobile {
    margin-bottom: $layout-04;
  }
}

.c-landing-hero__title { 
  font-weight: 800;
  margin-bottom: $space-05;

  @include mq-desktop {
    font-size: $font-X;
  }

  @include mq-tablet {
    font-size: $font-X;
  }

  @include mq-mobile {
    font-size: $font-xl;
  }
}

.c-home__title {
  font-weight: 800;
  margin-bottom: $space-05;

  @include mq-desktop {
    font-size: $font-xxl;
  }

  @include mq-tablet {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-home__text {
  @include mq-desktop {
    font-size: $font-md;
  }

  & + .o-button--lg {
    margin-top: $space-07;
  }
}

.c-home-hero {
  background-color: $black;
}

.c-home-hero__wrapper {
  max-width: 70rem;
}

.c-home-hero__title {
  color: $cream;
}

.c-home-hero__text {
  margin-bottom: $space-07;

  @include mq-desktop {
    font-size: $font-lg;
  }
}

.c-home-video {
  background: $light-200;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @include mq-desktop {
    padding-bottom: 28.8rem;
  }
  
  @include mq-tablet {
    padding-bottom: 20rem;
  }

  @include mq-mobile {
    padding-bottom: $layout-10;
  }
}

.c-home-video__wrapper {
  max-width: 119rem;
  width: 100%;
  z-index: 1;

  @include mq-desktop {
    padding-right: $space-04;
    padding-left: $space-04;
  }
  
  @include mq-tablet {
    height: auto;
    padding-top: 0;
  }
  @include mq-mobile {
    height: 0;
    padding-top: 59.14%;
  }

  video {
    width: 100%;
    height: auto;
    
    @include mq-tablet {
      padding-left: 0;
      padding-right: 0;
      position: relative;
    }

    @include mq-mobile {
      padding-right: $space-04;
      padding-left: $space-04;
      position: absolute;
      top: 0;
    }
  }
}

.c-home-video__triangle {
  width: 100%;
  background: linear-gradient(to top right, $light-200 0%, $light-200 50%, $black 50%, $black 100%);
  z-index: 0;
  position: absolute;
  top: 9.2rem;

  @include mq-desktop {
    height: 25rem;
  }

  @include mq-tablet {
    height: 40rem;
  }

  @include mq-mobile {
    height: 8rem;
  }

  &:before {
    content: "";
    background-color: $black;
    height: 9.2rem;
    width: 100%;
    display: block;
    top: -9.2rem;
  }
}


/* WHY NEERME? */

.c-landing-slides {
  width: 100%;
  background-color: $white;
  color: $slate-gray;

  @include mq-desktop {
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-bottom: $layout-08;
  }
}

.c-landing-slides__single {
  width: 100%;
  display: flex;
  align-items: center;
  
  @include mq-desktop {
    padding-bottom: $layout-11;
    padding-top: $layout-11;
  }

  @include mq-tablet {
    flex-direction: column;
    padding-right: $space-04;
    padding-left: $space-04;
    padding-top: $layout-11;
  }

  @include mq-mobile {
    flex-direction: column;
    padding: $layout-06 $space-04 0;
  }

  &:first-child {
    align-items: flex-start;

    .c-landing-slides__video {
      @include mq-desktop {
        margin-top: $layout-11 * -2;
      }
      @include mq-tablet {
        margin-top: $layout-11 * -2;
      }
      @include mq-mobile {
        margin-top: $layout-11 * -1;
      }
      
    }
  }

  &:nth-child(3n) {
    video {
      @include translateX(1px);
    }
  }
}

.c-landing-slides__video {
  display: flex;
  justify-content: flex-end;

  @include mq-desktop {
    width: 50%;
    overflow-x: hidden;
  }

  @include mq-tablet {
    width: 100%;
    height: 0;
    padding-top: 70.83%;
  }

  @include mq-mobile {
    width: 100%;
    height: 0;
    padding-top: 70.83%;
  }

  video, img {
    @include mq-desktop {
      width: auto;
      height: 60rem;
    }

    @include mq-tablet {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
    }

    @include mq-mobile {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
    }
  }
}

.c-landing-slides__content {
  @include mq-desktop {
    width: 50%;
    padding-right: 8%;
    padding-left: 8%;
  }

  @include mq-small-desktop {
    padding-right: 6%;
    padding-left: 6%;
  }

  @include mq-tablet {
    width: 100%;
    margin-top: $space-07 * -1;
    padding-right: 12%;
    padding-left: 12%;
  }

  @include mq-mobile {
    width: 100%;
    margin-top: $space-07 * -1;
  }
}

.c-landing-slides__list {
  margin-bottom: $space-07;

  li {
    background: url(../../../../assets/img/check-icon.png) no-repeat left 5px;
    background-size: 1.6rem auto;
    padding-left: $space-06;
    margin-bottom: $space-05;
  }
}

.c-landing-slides__emoji {
  font-size: $font-XX;
}

.c-landing__category {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: $space-04;
}


/* CAROUSEL */ 

.c-home-carousel {
  color: $white;
  z-index: 2;

  @include mq-desktop {
    padding-top: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    margin-bottom: $layout-02;
  }
  @include mq-mobile {
    padding-top: $layout-08;
  }

  .c-landing__category {
    color: $cream;
  }
}

.c-home-carousel__title {
  max-width: 65rem;
  padding-right: $space-05;
  padding-left: $space-05;
  width: 100%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $space-06;

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-home-carousel__dots {
  display: inline-block;
  border-radius: $radius-md;
  border: 1px solid $pistachio;
  color: $pistachio;
  text-transform: uppercase;
  

  @include mq-desktop {
    letter-spacing: 2px;
    padding: $space-03 $space-05;
  }
  @include mq-tablet {
    letter-spacing: 2px;
    padding: $space-03 $space-03;
  }

  @include mq-mobile {
    padding: $space-02 $space-03;
  }

  h6 {
    margin-bottom: 0;
    font-size: $font-xxs;
  }
}

.c-home-carousel__wrapper {
  @include mq-desktop {
    display: flex;
    align-items: flex-start;
  }
  @include mq-tablet {
    display: flex;
    align-items: flex-start;
  }
  @include mq-mobile {}
}

.c-home-carousel__video {
  z-index: 1;
  overflow: hidden;

  @include mq-desktop {
    width: 50%;
    height: 60rem;
  }

  @include mq-small-desktop {
    width: 70%;
    height: 50rem;
    right: 0;
    position: absolute;
  }

  @include mq-tablet {
    width: 80%;
    height: 50rem;
    right: 0;
    position: absolute;
  }

  @include mq-tablet-portrait {
    height: 50vh;
  }

  @include mq-mobile {
    width: 100%;
    padding-right: $space-04;
    padding-left: $space-04;
  }

  video {
    @include mq-desktop {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }
    @include mq-tablet {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }
    @include mq-mobile {
      width: 100%;
    }
  }

  @include mq-mobile {
    width: 100%;
    height: auto;
  }
}

.c-home-carousel__content {
  @include mq-desktop {
    width: 50%;
    padding: $layout-11 5%;
  }

  @include mq-small-desktop {
    width: 50%;
    padding: $layout-04 $space-08;
    z-index: 2; 
  }

  @include mq-tablet {
    width: 60%;
    padding: $layout-04 $space-06;
    z-index: 2; 
  }

  @include mq-mobile {
    max-width: 45rem;
    width: 100%;
    padding: $layout-02 $space-04;
  }
}

.c-home-carousel__subtitle {
  font-weight: 500;
  margin-bottom: $space-06;

  @include mq-desktop {
    font-size: $font-xxl;
  }
  @include mq-tablet {
    font-size: $font-lg;
  }
  @include mq-mobile {
    font-size: $font-md;
  }
}

.c-home-carousel__text {
  margin-bottom: $space-07;
  color: $light-200;

  @include mq-desktop {
    font-size: $font-md;
  }
  @include mq-tablet {
    font-size: $font-md;
  }
  @include mq-mobile {
    font-size: $font-bs;
  }
}

.c-home-carousel__link {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .c-icon {
    margin-right: $space-04;
  }
}


.SponsoredCarousel{
  .slick-slider {
    @include mq-desktop {
      padding-top: $layout-11;
    }
    @include mq-tablet {
      padding-top: $layout-08;
    }

    @include mq-mobile {
      padding-top: $layout-07;
    }
  }

  .slick-list {
    @include mq-small-desktop {
      min-height: 72vh;
    }

    @include mq-tablet {
      min-height: 60vh;
    }

    @include mq-tablet-portrait {
      min-height: 45vh;
    }
  }

  .slick-active .c-home-carousel__dots {
    background-color: $pistachio;
    color: $slate-gray
  }

  .slick-thumb {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;

    @include mq-tablet {
      align-items: center;
    }

    @include mq-mobile {
      flex-wrap: wrap;
    }

    li {
      @include mq-desktop {
        margin: 0 $space-04;
      }
      @include mq-tablet {
        margin: 0 $space-02;
      }

      @include mq-mobile {
        margin: 0 $space-01 $space-02;
      }
    }
  }

  .slick-dots {
    display: flex!important;
    top: 0;
    justify-content: center;
  }
}


/* TAGS */ 

.c-home-types {
  background-color: $pistachio;
  color: $slate-gray;
  margin-top: $layout-11 * -1;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mq-desktop {
    padding-top: $layout-11 * 2;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11 * 2;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-11 * 1.5;
    padding-bottom: $layout-08;
  }
}

.c-home-types__wrapper {
  max-width: 65rem;
  padding-right: $space-05;
  padding-left: $space-05;
  width: 100%;
  text-align: center;
  
  @include mq-desktop {
    margin-bottom: $layout-08;
  }
  @include mq-tablet{
    margin-bottom: $layout-08;
  }
  @include mq-mobile {
    margin-bottom: $layout-04;
  }
}

.c-home-types__events {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    text-align: center;
    align-self: flex-start;

    @include mq-desktop {
      border: 2px solid $dark-500;
      padding: $space-05 $space-06;
      margin: $space-03;
    }

    @include mq-tablet{
      border: 2px solid $dark-500;
      padding: $space-05 $space-06;
      margin: $space-03;
    }

    @include mq-mobile {
      border: 1px solid $dark-500;
      font-size: $font-sm;
      padding: $space-02 $space-02;
      margin: $space-02;
    }

  }
}


/* SUCCESS STORIES */ 

.c-landing-stories {
  background-color: $light-200;
  color: $slate-gray;
  min-height: 100vh;
  overscroll-behavior: contain;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-landing-stories__wrapper {
  @include mq-desktop {
    padding-top: $layout-05;
    padding-left: 12rem;
    padding-right: 6rem;
    width: 50%;
    height: calc(100vh - 40rem);
    position: -webkit-sticky;
    position: sticky;
    top: $layout-05;
    z-index: 1;
  }

  @include mq-small-desktop {
    padding-left: $layout-06;
    padding-right: 3rem;
  }

  @include mq-tablet {
    padding-left: 4rem;
    padding-right: 2rem;
    height: calc(100vh - 30rem);
    position: -webkit-sticky;
    position: sticky;
    top: $layout-05;
    z-index: 1;
  }

  @include mq-mobile {
    padding-bottom: $space-08;
    padding-left: $space-05;
    padding-right: $space-05;
    max-width: 45rem;
  }
}

.c-landing-stories__content, 
.c-landing-stories__list {
  @include mq-desktop {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include mq-tablet {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.c-landing-stories__list {
  @include mq-desktop {
    left: 50%;
    width: 50%;
    margin-top: calc(-100vh + 40rem);
    padding-left: 6rem;
    padding-right: 12rem;
    padding-top: $space-05;
  }

  @include mq-small-desktop {
    padding-left: 3rem;
    padding-right: $layout-06;
  }

  @include mq-tablet {
    padding-left: 2rem;
    padding-right: 4rem;
    margin-top: calc(-100vh + 30rem);
    padding-top: $space-05;
  }
  
  @include mq-mobile {
    padding-left: $space-05;
    padding-right: $space-05;
  }

  h3 {
    margin-bottom: 0;
  }
}

.c-landing-stories__quote {
  margin-top: $space-06;
  margin-bottom: $space-06;
  font-style: italic;
  font-weight: 500;
  line-height: $body-line-height;
}

.c-landing-stories__single {
  background-color: $white;
  border-radius: $radius-md;
  margin-bottom: $space-06;

  @include mq-desktop {
    padding: $space-07;
  }

  @include mq-tablet {
    padding: $space-05;
  }
  
  @include mq-mobile {
    padding: $space-05 $space-04;
  }

  p {
    @include mq-desktop {
      font-size: $font-md; 
    }
    
  }
}

.c-landing-stories__emoji {
  font-size: 4rem;
  line-height: 1.2;
  position: absolute;
  top: -2rem;
  right: -2rem;
}

.c-landing-stories__category {
  margin-bottom: $space-04;
  color: $pale-red;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include mq-mobile {
    font-size: $font-xxs;
  }
}

.c-landing-stories__link {
  color: $purple;
  display: inline-block;

  @include mq-desktop {
    margin-top: $space-05;
  }

  @include mq-mobile {
    margin-top: $space-02;
  }

  .c-icon {
    margin-right: $space-03;
  }
}


/* DATA */ 

.c-home-data {
  width: 100%;
  color: $slate-gray;
  background: $white;
  display: flex;
  justify-content: center;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-home-data__wrapper {
  max-width: 94rem;
  padding-right: $space-05;
  padding-left: $space-05;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex-wrap: wrap;

  li {
    @include mq-desktop {
      width: calc(100%/4);
      padding-left: $space-05;
      padding-right: $space-05;
    }

    @include mq-tablet{
      width: calc(100%/2);
      padding-left: $space-05;
      padding-right: $space-05;
      margin-bottom: $space-05;
    }

    @include mq-mobile {
      width: 100%;
      margin-bottom: $space-07;
    }
  }

  h2 {
    font-size: $font-XX;
    font-weight: 800;

    @include mq-desktop {
      margin-bottom: $space-04;
    }
    @include mq-tablet{
      margin-bottom: $space-04;
    }
    @include mq-mobile {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}


/* MAGNET */

.c-landing-magnet {
  display: flex;
  justify-content: center;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-landing-magnet__wrapper {
  max-width: 56rem;
  padding-right: $space-05;
  padding-left: $space-05;
  text-align: center;
}

.c-landing-magnet__title {
  font-weight: 800;
  margin-bottom: $space-06;

  @include mq-desktop {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-home-magnet {
  background-color: $pistachio;
  color: $slate-gray;
}
