
$space-unit: 1.6rem;

/* SPACE COMPONENT */
$space-01: 0.125 * $space-unit;  /* 2px */
$space-02: 0.25 * $space-unit;   /* 4px */
$space-03: 0.5 * $space-unit;    /* 8px */
$space-04: 0.75 * $space-unit;   /* 12px */
$space-05: 1 * $space-unit;      /* 16px */
$space-06: 1.5 * $space-unit;    /* 24px */
$space-07: 2 * $space-unit;      /* 32px */
$space-08: 2.5 * $space-unit;    /* 40px */

/* SPACE LAYOUT */
$layout-01: 1 * $space-unit;    /* 16px */
$layout-02: 1.5 * $space-unit;  /* 24px */
$layout-03: 2 * $space-unit;    /* 32px */
$layout-04: 2.5 * $space-unit;  /* 40px */
$layout-05: 3 * $space-unit;    /* 48px */
$layout-06: 3.5 * $space-unit;  /* 56px */
$layout-07: 4 * $space-unit;    /* 64px */
$layout-08: 4.5 * $space-unit;  /* 72px */
$layout-09: 5 * $space-unit;    /* 80px */
$layout-10: 5.5 * $space-unit;  /* 88px */
$layout-11: 6 * $space-unit;    /* 96px */

/* COMPONENT DEFAULT */
$padding-block-xl: $space-06;
$padding-block: $space-05;
$padding-card-lg: $space-04;
$padding-card-sm: $space-03;
$padding-list-lg: $space-05;
$padding-list-sm: $space-03;

/* TYPOGRAPHY SPACING */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

p {
  margin-top: $space-04;
  margin-bottom: $space-04;
}
