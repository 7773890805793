/* VARIABLES */
$max-mobile: 46em;
$max-mobile-landscape: 55em;
$max-tablet: 55em;

$radius-sm: 0.4rem;
$radius-md: 0.8rem;
$radius-lg: 1.6rem;
$radius-badge: 1.92rem;
$radius-circle: 50%;

/* MIXINS */

@mixin animate {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@mixin shadow() {
  box-shadow: 0px 2px 4px color("light", "500");
}

@mixin radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin translateX($x) {
  @include transform(translateX($x));
}

@mixin translateY($y) {
  @include transform(translateY($y));
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

/* MEDIA QUERIES */

@mixin mq-mobile {
  @media screen and (max-width: #{$max-mobile - .01em}) {
    @content;
  }
}

@mixin mq-mobile-landscape {
  @media screen 
    and (max-width: #{$max-mobile-landscape}) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {
      @content;
  }
}

@mixin mq-tablet-portrait {
  @media screen 
    and (min-width: #{$max-mobile}) 
    and (max-width: 69em) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait) {
      @content;
  }
}

@mixin mq-tablet {
  @media screen and (min-width: #{$max-mobile}) and (max-width: #{$max-tablet}) {
    @content;
  }
}

@mixin mq-small-desktop {
  @media screen and (min-width: #{$max-tablet}) and (max-width: 69em) {
    @content;
  }
}

@mixin mq-desktop {
  @media screen and (min-width: #{$max-mobile}) {
    @content;
  }
}

/* MAIN DECLARATIONS */

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

* {
  scrollbar-width: thin;
}

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  width: 100%;
}

a img {
  outline: none;
}

img {
  border: 0;
  border-style: none;
}

figure {
  margin: 0;
}

ul {
  padding-left: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:focus {
  outline: none;
}
