.c-livestream__video {
  width: 100%;
  flex: 1;
  background: black;
  overflow: hidden;

  @include mq-desktop {
    height: 100%;
    padding-top: 0;
  }

  @include mq-tablet {
    height: 0;
    padding-top: 56.5%;
  }

  @include mq-mobile {
    height: 0;
    padding-top: 56.5%;
  }
}

.c-livestream__adlarge {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #0f0f15;

  img {
    display: block;
    max-width: 100rem;
  }
}

/* FLOW PLAYER */

.flowplayer {
  background-color: black !important;
  height: 100%;

  @include mq-desktop {
    max-height: calc(100vh - 18.4rem);
  }

  @include mq-tablet {
    position: absolute !important;
    top: 0;
  }

  @include mq-mobile {
    position: absolute !important;
    top: 0;
  }

  &.is-fullscreen {
    max-height: 100%;
  }

  .fp-secondary {
    flex: 0;
    display: flex;
    flex-direction: row;
  }
}

.fp-menu {
  h3 {
    font-size: $font-xs !important;
  }
}

.fp-menu-container,
.fp-controls {
  position: unset;
}

/* PLYR */
.c-plyr {
  height: 100%;
  width: 100%;

  .plyr video {
    width: 100% !important;

    @include mq-desktop {
      max-height: calc(100vh - 17.7rem);
    }

    @include mq-tablet {
      max-height: 100%;
    }
  }

  .plyr__control--overlaid {
    background: $pistachio;
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: $pistachio;
  }

  .plyr--full-ui input[type="range"] {
    color: $pistachio;
  }
}

.plyr {
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen {
    video {
      height: 100% !important;
      max-height: 100% !important;
    }
  }
}

.plyr:fullscreen video {
  max-height: 100% !important;
  height: 100% !important;
}

.plyr__controls {
  justify-content: space-between;

  .neerme__controls,
  .neerme__controls_end {
    display: none;
  }
}

.plyr__settings {
  display: flex;
  justify-content: flex-end;

  & > .plyr__control:last-child .plyr__tooltip {
    left: auto;
    right: 0;
    transform: translate(0, 0);
    transform-origin: 100% 100%;

    &::before {
      left: auto;
      right: calc((18px / 2) + calc(10px * 0.7));
      right: calc(
        (var(--plyr-control-icon-size, 18px) / 2) +
          calc(var(--plyr-control-spacing, 10px) * 0.7)
      );
      transform: translateX(50%);
    }
  }
}

/* VIDEO JS */

.video-js__wrapper {
  width: 100%;
  height: 100%;

  @include mq-tablet {
    position: absolute !important;
    top: 0;
    max-height: 100%;
  }

  @include mq-mobile {
    position: absolute !important;
    top: 0;
    max-height: 100%;
  }
}

.vjs_video_3-dimensions.vjs-fluid {
  @include mq-desktop {
    max-height: calc(100vh - 12rem);
    padding-top: 0;
    height: 100%;
  }

  @include mq-tablet {
    position: absolute !important;
    max-height: 100%;
    top: 0;
  }

  @include mq-mobile {
    position: absolute !important;
    top: 0;
    max-height: 100%;
  }

  video {
    width: 100% !important;
  }

  &.vjs-fullscreen {
    height: 100% !important;
    max-height: 100% !important;

    video {
      height: 100% !important;
      max-height: 100% !important;
    }
  }

  .vjs-big-play-button {
    width: 8.4rem;
    height: 8.4rem;
    background: none;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets/img/play.png");
    background-size: 9rem;
    border: none;
    border-radius: $radius-circle;
    top: 50%;
    left: 50%;
    margin-top: -44px;
    margin-left: -44px;
    color: purple;

    .vjs-icon-placeholder {
      display: none;
    }
  }

  .vjs-control-bar {
    padding: 0 $space-02;
    height: 4rem;
  }

  .vjs-control {
    display: flex;
    align-items: center;
    justify-content: center;

    &.vjs-quality-selector {
      width: 6em;
      text-align: center;
    }
  }

  .vjs-menu-button-popup .vjs-menu {
    left: -2em;
  }

  .vjs-control:focus:before,
  .vjs-control:hover:before,
  .vjs-control:focus {
    text-shadow: none;
    color: $pistachio;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    max-height: 16em;
  }
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-image: url("../../../../assets/img/play.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8.4rem;
  border: none;
}

.vjs-picture-in-picture
  .vjs-picture-in-picture-control
  .vjs-icon-placeholder:before {
  font-size: 2rem;
}

.vjs-modal-dialog .vjs-modal-dialog-content,
.video-js .vjs-modal-dialog,
.vjs-button > .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: relative;
}

// WATER MARK
$padding-custom: 25%;
.vjs-id {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  animation-duration: 15s;
  animation-timing-function: linear;
  display: flex;
  justify-content: flex-start;

  @include mq-desktop {
    padding: $space-04;
  }

  @include mq-tablet {
    padding: $space-02;
  }

  @include mq-mobile {
    padding: $space-02;
  }

  .id-item {
    width: 18px;
    height: 18px;
    background: rgba($color: #000000, $alpha: 0.3);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    opacity: 0.8;
  }

  &.top-center {
    animation-name: show-animation;
    top: $padding-custom;
    left: 50%;
    @include translateX(-50%);
  }

  &.top-left {
    animation-name: show-animation;
    top: $padding-custom;
    left: $padding-custom;
  }

  &.top-right {
    animation-name: show-animation;
    top: $padding-custom;
    right: $padding-custom;
  }

  &.bottom-center {
    animation-name: show-animation;
    bottom: $padding-custom;
    left: 50%;
    @include translateX(-50%);
  }

  &.bottom-left {
    animation-name: show-animation;
    left: $padding-custom;
    bottom: $padding-custom;
  }

  &.bottom-right {
    animation-name: show-animation;
    right: $padding-custom;
    bottom: $padding-custom;
  }

  &.left-center {
    animation-name: show-animation;
    left: $padding-custom;
    top: 50%;
    @include translateY(-50%);
  }

  &.right-center {
    animation-name: show-animation;
    right: $padding-custom;
    top: 50%;
    @include translateY(-50%);
  }
}

@keyframes show-animation {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
