.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $dark-400;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-top: $layout-06;
  padding-bottom: $space-05;
  transition: 0.3s ease-in;
  display: none;
}

.c-overlay__close {
  top: $space-03;
  right: $space-03;
  padding: $space-04;
  color: $white;
  position: absolute;
}

.c-overlay__name {
  font-weight: 400;
  font-size: $font-lg;
  padding: $space-03 $space-06;
  margin-bottom: $space-05;

  small {
    font-size: $font-bs;
    display: block;
    font-style: italic;
    font-weight: 400;
    color: $light-400;
    margin-top: $space-02;
  }
}

.c-overlay__links {
  a {
    color: $white;
    display: flex;
    align-items: center;
    padding: $space-05 $space-06;
    text-decoration: none;

    &:hover,
    &:active {
      background-color: $dark-500;
      color: $pistachio;
    }
  }

  span {
    font-size: $font-bs;
  }

  .c-icon {
    margin-right: $space-04;
  }
}
