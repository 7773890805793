.c-chat {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include mq-desktop {
    flex: 1 1 auto;
    height: 100%;
  }

  @include mq-tablet {
    height: 40vh;
  }

  @include mq-mobile {
    height: 60vh;
  }

  @include mq-mobile-landscape {
    height: 80vh;
  }
}

.c-chat__empty {
  padding: $space-04;

  .c-empty__title {
    margin-bottom: $space-04;
  }

  .o-field {
    margin-bottom: $space-06;
  }
}

.c-empty__button-wrapper {
  display: flex;
  justify-content: space-between;
}

.c-chat__title {
  text-transform: uppercase;
  color: $cream;
  letter-spacing: 2px;
  margin-bottom: $space-04;
  font-size: $font-xxs;
}

.c-avatar__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: $space-04;

  &.is-top-fan {
    .c-avatar__single {
      margin-right: $space-04;
      margin-bottom: $space-04;
    }

    a.c-avatar {
      background-color: $pale-red;
    }
  }

  &.is-neerme {
    // overflow-y: auto;

    // @include mq-desktop {
    //   max-height: calc(100vh - 46rem);
    // }

    // @include mq-mobile-landscape {
    //   max-height: calc(100vh - 20rem);
    // }

    // @include mq-mobile {
    //   justify-content: space-evenly;
    // }
  }

  .c-avatar {
    img {
      width: 100%;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: lighten($purple, 25%);
    }
  }
}

.c-avatar__single {
  display: flex;
  flex-wrap: wrap;
  margin-right: $space-03;
  margin-bottom: $space-03;

  &:nth-child(8n) {
    @include mq-desktop {
      margin-right: 0;
    }

    @include mq-tablet {
      margin-right: $space-03;
    }
  }

  .c-avatar__lock {
    right: -.8rem;
  }
}

.c-avatar {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: $radius-circle;
  background-color: $dark-100;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq-tablet {
    width: 4rem;
    height: 4rem;
  }

  &:hover {
    background-color: $dark-200;
  }

  img {
    display: block;
  }
}

.c-avatar__lock {
  width: 1.8rem;
  height: 1.8rem;
  background-color: $light-500;
  color: $slate-gray;
  border-radius: $radius-circle;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-chat__container {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0;
}

.c-chat__list {
  padding: $space-05 $space-04;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
}

.c-chat__form {
  display: flex;
  padding: $space-05 $space-04;
  align-items: center;
  border-top: 1px solid $dark-400;
  flex-wrap: wrap;
}

.c-chat__input {
  flex: 1;

  input {
    font-family: $font-proxima;
    display: block;
    padding-right: 6.4rem;
    height: 4rem;

    @include mq-desktop {
      font-size: $font-sm;
    }

    @include mq-mobile {
      font-size: $font-bs;
    }

    &.is-warning {
      border-color: $yellow;
      box-shadow: 0px 0px 4px $yellow;
    }
  }
}

.c-chat__button {
  position: absolute;
  right: .4rem;
  top: 0;
  height: 4rem;
  width: 6.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.c-chat__send {
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: $white;
  border: none;
  cursor: pointer;

  &:hover,
  &:active {
    color: $pistachio;
  }
}

.c-chat__upgrade-wrapper {
  border: 1px solid $dark-300;
  border-radius: $radius-sm;
  padding: $space-04 $space-04 $space-02;
  margin-bottom: $space-06;

  .c-levelup {
    margin-bottom: $space-05;
  }
}

.c-chat__levelup {
  width: 3.6rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark-200;
  color: $pistachio;
  border-radius: $radius-sm;
  margin-left: $space-04;

  &:hover {
    background-color: $dark-300;
  } 
}

.c-chat__warning {
  font-size: $font-sm;
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: $space-03;
  color: $light-200;

  strong {
    color: $yellow;
    display: block;
  }
}

.c-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: $space-04;

  .c-icon {
    transform: rotate(-90deg);
    margin-left: $space-03;
  }

  .c-load-more__button {    
    font-family: $font-proxima;
    border: 1px solid $white;
    color: white;
    border-radius: $radius-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-sm;
    font-weight: 600;
    flex-basis: 20rem;
    background-color: rgba($dark-400,.8);
    padding: $space-03 $space-05;
    cursor: pointer;

    &:hover {
      background-color: $dark-400;
    }
  }
}
