@mixin card-container {
  background-color: $dark-400;
  overflow: hidden;
  border-radius: $radius-md;
}

@mixin card-date {
  margin-right: $space-03;
  text-align: center;
}

@mixin card-day {
  line-height: 0.8;
  letter-spacing: 1px;
  display: block;
  color: $pistachio;
}

.c-live-card__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;

  @include mq-mobile {
    width: 100%;
    height: 25rem;
    @include card-container;
  }

  @include mq-desktop {
    width: 40rem;
    height: 25rem;
    @include card-container;
  }

  @include mq-tablet {
    width: 100%;
    height: 25rem;
    @include card-container;
  }

  &:hover {
    @include mq-mobile {
      // background: $dark-400;
    }
    .c-live-card__image img {
      @include mq-desktop {
        height: 105%;
        width: auto;
      }
    }
  }
}

.c-live-card__image {
  background-size: 19.75%;
  background-color: $purple;
  background-image: url(../../../../assets/img/event-back.png);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba($dark-500, 0.8);
    background: linear-gradient(
      rgba(0,0,0, 0.2) -30%,
      rgba(0,0,0, 0.9) 90%
    );
  }

  img {
    width: auto;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    @include translateX(-50%);

    @include mq-desktop {
      @include animate;
    }
  }
}

.c-live-card__live {
  display: flex;
  align-items: center;
  color: $white;
  background: $pale-red;
  position: absolute;
  padding: $space-02 $space-03;
  border-top-left-radius: $radius-md;
  border-bottom-left-radius: $radius-md;
  right: 0;
  top: 1.6rem;
}

.c-live-card__alert {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 1.3;

  @include mq-mobile {
    font-size: $font-xxs;
    font-weight: 500;
  }

  @include mq-desktop {
    font-size: $font-xxs;
  }
}

.c-live-card {
  display: flex;
  color: $white;
  width: 100%;
  
  @include mq-desktop {
    padding: $space-06;
  }
  @include mq-mobile {
    padding: $space-04;
  }
}

.c-live-card__detail {
  flex: 1;
}

.c-live-card__date {
  margin-top: $space-01;
  width: 4.2rem;
  flex: 0 0 4.2rem;
  @include card-date;
}

.c-live-card__day {
  font-family: $font-aktiv-ext;
  font-weight: 700;
  font-size: $font-xl;
  @include card-day;
}

.c-live-card__month {
  font-size: $font-sm;
  font-family: $font-aktiv-ext;
  font-size: $font-xs;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.c-live-card__title {
  font-weight: 500;
  margin-bottom: $space-02;

  @include mq-mobile {
    font-size: $font-md;
  }
}

.c-live-card__hour {
  color: $cream;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $font-aktiv-ext;
  font-size: $font-sm;
  letter-spacing: 2px;
}

.c-live-card__button {
  display: flex;

  @include mq-desktop {
    padding-left: 7.4rem;
    padding-right: $space-06;
    padding-bottom: $space-06;
  }

  @include mq-tablet {
    padding: $space-03 $space-04 $space-05;
  }

  @include mq-mobile {
    padding: $space-03 $space-04 $space-05;
  }

  .o-button--sm {
    display: inline-flex;
    margin-right: $space-04;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $font-xs;
    padding-right: $space-05;
    padding-left: $space-05;
  }
}
