@mixin block {
  border-radius: $radius-md;
  margin-bottom: $space-05;
  padding: $space-05 $space-04;
}

.c-poll {
  display: flex;
  flex-direction: column;

  @include mq-mobile {
    border-bottom: 1px solid $dark-400;
  }

  @include mq-desktop {
    flex: 1;
  }

  @include mq-tablet {
    border-bottom: 1px solid $dark-400;
  }
}

.c-poll__container {
  padding: $space-05;

  @include mq-mobile {
    height: 40rem;
    overflow-y: scroll;
  }
  @include mq-desktop {
    flex: 1 1 auto;
    height: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  @include mq-tablet {
    height: 40rem;
    overflow-y: scroll;
  }

  li:first-child {
    .c-poll__single {
      background-color: $dark-100;

      &:hover {
        background-color: $dark-300;
      }

      .c-poll__title {
        @include mq-desktop {
          margin-bottom: $space-03;
        }

        @include mq-mobile-landscape {
          font-size: $font-sm;
        }
      }

      .c-poll__summary {
        border-bottom-color: $dark-200;
      }
    }
  }
}

.c-poll__empty {
  background: $purple;
  @include block;

  .c-poll__title {
    font-family: $font-aktiv-ext;
    color: $pale-red;
  }

  .c-poll__button {
    margin-top: $space-07;
  }
}

.c-poll__cta {
  flex: 0 0 7.5rem;
  padding: $space-04 $space-07;
  background: $dark-500;
  align-items: center;
  border-top: 1px solid $dark-300;

  @include mq-mobile {
    display: none;
  }

  @include mq-tablet {
    display: none;
  }
}

.c-poll__form {
  input + input {
    margin-top: $space-03;
  }
}

label.c-poll__label {
  color: $light-300;
  font-weight: 400;
  font-size: $font-sm;
}

.c-poll__fade {
  width: 100%;
}
