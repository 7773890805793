[class^='button'] {
 & + & {margin-left: $space-05}
}

/* ADMIN BUTTONS */

@mixin button {
  font-family: $font-aktiv-ext;
  border: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .c-icon {margin-right: $space-03}
}

a, button, div {
  .o-button--xs {
    font-size: $font-xs;
    padding: $space-01 $space-03;
    border-radius: $radius-sm;
    font-weight: 500;
    text-transform: uppercase;
    @include button;
  }

  .o-button--sm {
    font-size: $font-sm;
    padding: $space-02 $space-04;
    font-weight: 500;
    border-radius: $radius-md;
    @include button;
  }

  .o-button--bs {
    font-size: $font-xs;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: $space-04 $space-05;
    font-weight: 700;
    border-radius: $radius-md;
    @include button;
  }

  .o-button--lg {
    font-size: $font-xs;
    font-weight: 700;
    padding: $space-05 $space-06;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: $radius-md;
    @include button;
  }

  .o-button--red {
    color: $white;
    background-color: $pale-red;
    font-weight: 600;

    &:hover, &:active {background-color: darken($pale-red, 8%);}
  }

  .o-button--cream {
    color: $purple;
    background-color: $cream;

    &:hover, &:active {background-color: darken($cream, 10%);}
  }

  .o-button--green {
    color: $slate-gray;
    background-color: $pistachio;

    &:hover, &:active {background-color: darken($pistachio, 8%);}
  }

  .o-button--purple {
    color: $white;
    background-color: $purple;

    &:hover, &:active {background-color: darken($purple, 8%);}
  }

  .o-button--inactive {
    color: $white;
    background-color: $dark-500;
    font-weight: 400;
  }

  .o-button--outline {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    font-weight: 500;

    &.is-default {
      &:hover, &:active {
        background-color: $pistachio;
        color: $slate-gray;
      }
    }

    &.is-dark {
      color: $slate-gray;
      border-color: $slate-gray;

      &:hover, &:active {
        background-color: $slate-gray;
        color: $white;
      }
    }

    &.is-light {
      color: $white;
      border-color: $white;

      &:hover, &:active {
        background-color: $white;
        color: $dark-400;
      }
    }
  }

  .o-button--link {
    background-color: transparent;
    color: $light-300;
    padding-left: 0;

    &:hover {
      text-decoration: underline;
    }
  }  
}

