/* TROUBLESHOOTING PAGE */

.c-layout.is-help {
  background-color: $light-300;
  color: $slate-gray;
  display: flex;
  align-items: center;

  @include mq-desktop {
    padding-top: $layout-05;
  }

  @include mq-mobile {
    padding-top: $layout-04;
  }
}

.c-help {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 120rem;
  
  @include mq-desktop {
    padding-right: $layout-04;
    padding-left: $layout-04;
  }

  @include mq-mobile {
    padding-right: $space-04;
    padding-left: $space-04;
  }

  &.is-demo {
    max-width: 75rem;

    .inbound-iframe {
      @include mq-desktop {
        height: 220rem
      }

      @include mq-mobile {
        height: 250rem
      }
    }
  }

  p, li {
    @include mq-desktop {
      font-size: $font-md;
    } 
  }

  li {
    margin-bottom: $space-04;
  }

  p {
    & + p {
      @include mq-desktop {
        margin-top: $layout-02;
      }

      @include mq-mobile {
        margin-top: $layout-01;
      }
    }
  }

  h3 {
    margin-top: $layout-03;
  }

  a {
    color: $pale-red;
  }
}

.c-help__hero {
  padding-top: $layout-04;

  span {
    display: block;
    font-size: $font-XXX;
  }

  p {
    max-width: 70rem;
  }
}

.c-help__title {
  font-weight: 900;
  margin-bottom: $space-05;

  @include mq-desktop {
    font-size: $font-X;
  }
}

.c-help__summary {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: $layout-04;
  padding-top: $layout-04;
  padding-bottom: $layout-04;
  border-top: 1px solid $light-400;
  border-bottom: 1px solid $light-400;

  &.is-streaming-help {
    .c-help__block {
      @include mq-mobile {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      ol {
        @include mq-mobile {
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  ol {
    @include mq-mobile {
      padding-left: 0;
    }
  }
}

.c-help__block {
  @include mq-desktop {
    flex: 0 0 50%;
    padding-right: $layout-04;
  }

  @include mq-tablet {
    flex: 0 0 100%;
  }

  @include mq-mobile {
    flex: 0 0 100%;
    margin-bottom: $layout-02;
  }

  &:last-child {
    @include mq-mobile {
      margin-bottom: 0;
    }
  }

  h2 {
    @include mq-mobile {
      font-size: $font-md;
    }
  }

  span {
    margin-right: $space-04;

    @include mq-desktop {
      font-size: $font-xl;
    }

    @include mq-mobile {
      font-size: $font-lg;
    }
  }
}


.c-help__answers {
  width: 100%;
  padding-bottom: $layout-05;
  border-bottom: 1px solid $light-400;

  @include mq-desktop {
    display: flex;
    align-items: flex-end;
  }

  @include mq-tablet {
    display: block;
  }

  h1 {
    margin-top: $layout-06;

    @include mq-mobile {
      font-size: $font-lg;
      margin-bottom: $space-04;
    }
  }

  ul {
    margin-top: $layout-03;
  }

  li, code {
    padding: $space-04;
    border: 1px solid $light-500;

    &:last-child {
      margin-bottom: 0;
    }
  }

  code {
    font-size: $font-md;
    display: inline-block;
  }

  p + code {
    margin-bottom: $layout-01;
  }

  img {
    margin-top: $layout-03;
    display: block;
  }
}

.c-help__wrapper {
  max-width: 70rem
}

.c-help__back {
  flex: 1;
  display: flex;
  align-items: center;

  @include mq-desktop {
    justify-content: flex-end;
    text-align: right;
  }

  @include mq-tablet {
    justify-content: flex-start;
    text-align: left;
    margin-top: $layout-03;
  }

  @include mq-mobile {
    margin-top: $layout-03;
    justify-content: flex-start;
    text-align: left;
  }

  .c-icon {
    transform: rotate(-90deg);
    margin-right: $space-02;
  }
}

.c-help__icon {
  background: $light-400;
  
  border-radius: $radius-sm;
  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;

  @include mq-desktop {
    height: 2.8rem;
    width: 2.8rem;
    margin-left: $space-02;
    margin-right: $space-02;
  }

  @include mq-mobile {
    height: 2rem;
    width: 2rem;
    margin-left: $space-01;
    margin-right: $space-01;
  }

  .c-icon {
    @include mq-mobile {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}


/* LEGAL TERMS */
.c-legal {
  width: 90%;
  max-width: 60rem;
  margin: $layout-08 auto;
  padding-top: $layout-03;

  ul {
    margin-top: $space-06;
    margin-bottom: $space-06;
    padding-left: $space-04;
  }

  li {
    margin-bottom: $space-03;
    list-style-type: inherit;
  }

  p + h3 {
    margin-top: $space-08
  }
}

/* INBOUND CAMPAIGN */

.inbound-iframe {
  border: none;
}