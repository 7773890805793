.c-empty {
  margin: $space-04;
  padding: $space-05;
  background-color: $dark-400;
  border-radius: $radius-md;
  flex: 0 1 auto;
}

.c-empty__title {
  margin-top: 0;

  small {
    font-family: $font-proxima;
    display: block;
    font-weight: 400;
    margin-top: $space-03;
    color: $cream;
    font-size: $font-sm;
  }
}

.c-empty__button {
  margin-top: $space-05;
}