.c-upgrade__modal {
  display: none;
}
.c-upgrade__active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba($black, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.c-upgrade {
  width: 100%;
  margin-left: $space-04;
  margin-right: $space-04;
  border: 1px solid $dark-200;
  background-color: $slate-gray;
  border-radius: $radius-md;

  &.is-large {
    max-width: 66rem;
  }

  &.is-checkout {
    max-width: 66rem;
  }

  &.is-registry {
    max-width: 50rem;
  }

  &.is-finish {
    max-width: 60rem;
    display: flex;
    justify-content: center;
  }
}

.c-upgrade__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @include mq-desktop {
    width: 4.8rem;
    height: 4.8rem;
    top: -2.4rem;
  }

  @include mq-mobile {
    width: 4rem;
    height: 4rem;
    top: -2rem;
  }

  // &.is-badge {
  //   border-radius: $radius-circle;
  //   background-color: $slate-gray;
  //   border: 1px solid $dark-200;
  //   color: $pistachio;
  // }

  // .c-icon {
  //   @include mq-mobile {
  //     width: 2rem;
  //     height: 2rem;
  //   }
  // }
}

.c-upgrade__header {
  border-bottom: 1px solid $dark-200;
  text-align: center;

  @include mq-desktop {
    padding: $space-07 $space-05 $space-06;
  }

  @include mq-mobile {
    padding: $space-06 $space-04 $space-04;
  }

  span {
    @include mq-mobile {
      font-size: $font-sm;
    }
  }
}

.c-upgrade__back,
.c-upgrade__cancel {
  position: absolute;
  top: 0;
  padding: $space-04;
  color: $white;
}

.c-upgrade__back {
  left: 0;
}

.c-upgrade__cancel {
  right: 0;
}

.c-upgrade__title,
.c-upgrade__subtitle {
  // color: $cream;
}

.c-upgrade__title {
  font-weight: 800;
  margin-bottom: $space-03;

  @include mq-mobile {
    font-size: $font-bs;
  }
}

.c-upgrade__subtitle {
  margin-bottom: $space-03;
  font-family: $font-proxima;
}

.c-upgrade__container {
  overflow: auto;
  display: flex;

  @include mq-desktop {
    height: 70vh;
  }

  @include mq-tablet {
    height: 80vh;
  }

  @include mq-mobile-landscape {
    flex-direction: column;
    height: 55vh !important;
    -webkit-overflow-scrolling: touch;
  }

  @include mq-mobile {
    flex-direction: column;
    height: 70vh;
    -webkit-overflow-scrolling: touch;
  }
}

.xsolla-iframe {
  border: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.c-upgrade__column {
  @include mq-desktop {
    width: calc(100% / 2);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @include mq-tablet {
    width: calc(100% / 2);
  }

  @include mq-mobile-landscape {
    width: 100%;
  }

  @include mq-mobile {
    width: 100%;
  }

  &:first-child {
    @include mq-desktop {
      border-right: 1px solid $dark-200;
    }

    @include mq-tablet {
      border-right: 1px solid $dark-200;
    }

    @include mq-mobile-landscape {
      order: 2;
    }

    @include mq-mobile {
      order: 2;
    }
  }

  &:last-child {
    @include mq-mobile-landscape {
      order: 1;
      border-bottom: 1px solid $dark-200;
    }

    @include mq-mobile {
      order: 1;
      border-bottom: 1px solid $dark-200;
    }
  }

  // &:nth-child(2n) {
  //   display: none;
  // }

  &.is-chat {
    @include mq-desktop {
      padding: $space-06 $space-05 $space-05;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    @include mq-mobile {
      padding: $space-05 $space-04 $space-04;
      display: block;
    }
  }

  strong {
    font-size: $font-sm;

    @include mq-desktop {
      margin-bottom: $space-03;
    }

    @include mq-mobile {
      margin-bottom: $space-02;
    }
  }
}

.c-upgrade__chat {
  border-radius: 8px;
  background: $dark-500;
  flex: 1;

  @include mq-desktop {
    height: 90%;
    overflow-y: auto;
  }
}

.c-upgrade__chat-wrapper {
  padding: $space-04 $space-03;
}

.c-upgrade__column-wrapper {
  flex: 1;
  overflow: auto;
  width: 100%;

  @include mq-desktop {
    padding: $space-06 $space-05 0;
  }

  @include mq-mobile {
    padding: $space-05 $space-04 0;
  }
}

.c-upgrade__button-wrapper {
  @include mq-desktop {
    padding: $space-05;
  }

  @include mq-mobile {
    padding: $space-04;
  }
}
 
.c-upgrade__block {
  margin-bottom: $space-05;
}

.c-upgrade__list {
  &.c-stickers__container {
    @include mq-mobile {
      margin-bottom: 0;
    }  
  }

  &.is-sticker {
    li {
      margin-right: $space-03;

      @include mq-desktop {
        width: 4rem;
        height: 4rem;
      }

      @include mq-mobile {
        width: 3.2rem;
        height: 3.2rem;
      }
      
      img {
        width: 100%;
      }
    }
  }

  &.is-avatar {
    display: flex;
    flex-wrap: wrap;

    li {
      background-color: $pale-red;

      @include mq-desktop {
        width: 3.2rem;
        height: 3.2rem;
      }

      @include mq-mobile {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    img {
      width: 100%;
    }
  }

  li {
    margin-top: $space-02;
    margin-bottom: $space-02;

    @include mq-desktop {
      margin-right: $space-03;
    }

    @include mq-mobile {
      margin-right: $space-02;
    }
  }
}

a.c-upgrade__button {
  @include mq-mobile {
    padding: $space-03;
  }
}


.c-upgrade__form {
  padding: $space-04;
}

.c-upgrade__alert {
  background-color: $pistachio;
  color: $slate-gray;
  position: absolute;
  top: 0;
  padding: $space-02 $space-03;
  width: 100%;
  text-align: center;
}

.c-upgrade__finish {
  padding: $layout-07 $space-04 $layout-04;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  max-width: 45rem;

  .o-button--outline {
    width: 30rem;
    margin-top: $space-07;
    color: $white;
    border-color: $white;
  }

  p {
    text-align: center;
  }

  .c-upgrade__title {
    text-align: center;
    color: $pistachio;
    margin-bottom: 0;

    span {
      font-weight: normal;
      display: block;
    }
  }
}

.c-upgrade__message {
  max-width: 19rem;
}

.c-upgrade__badge {
  width: 8.8rem;
  height: 8.8rem;
  border-radius: $radius-circle;
  background-color: $dark-500;
  color: $pistachio;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $space-06;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.c-upgrade__currency {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    font-size: $font-sm;
    margin-top: $space-04;
    color: $light-200;
    font-weight: 500;
    font-style: italic;
  }
}

.c-upgrade__flag {
  font-size: $font-bs;
  line-height: 1;
  margin-right: $space-02;
}

/* ANIMATION */

.c-upgrade__chat .c-chat__message-wrapper {
  animation-fill-mode: forwards;
  animation-name: chat-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0,.76,.35,.99);
  transform: translateX(-5rem);
  opacity: 0;

  &:nth-child(1) {animation-delay: 0;}
  &:nth-child(2) {animation-delay: 1.5s;}
  &:nth-child(3) {animation-delay: 3s;}
  &:nth-child(4) {animation-delay: 4.5s;}
  &:nth-child(5) {animation-delay: 6s;}
  &:nth-child(6) {animation-delay: 7.5s;}
}

@keyframes chat-animation {
  0% {
    transform: translateX(-10rem);
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}