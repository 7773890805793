
$background-color   : $light-100;
$foreground-color   : $dark-300;
$arrow-size         : 4px;
$vertical-shift     : 8px;
$multiline-width    : 240px;
$tooltip-padding    : $space-01 $space-02;
$roundness          : $radius-sm; // 0 || border-radius
$shadow             : 0 5px 15px -5px rgba(0, 0, 0, .65); // 0 || box-shadow
$load-styles        : true !default; // Extra styles needed?

[data-tooltip] {
  font-family: $font-proxima;
  position: relative;
  display: inline-block;

  &:before, &:after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -$vertical-shift);
      z-index: 1000;
      pointer-events: none;
      user-select: none;
      opacity: 0;
      transition: opacity .35s ease .25s;
  }

  // Tooltip Body
  &:before {
      content: attr(data-tooltip);
      background: $background-color;
      color: $foreground-color;
      padding: $tooltip-padding;
      white-space: nowrap;
      bottom: 100%;
      @if ($roundness != 0)   { border-radius: $roundness; }
      @if ($shadow != 0)      { box-shadow: $shadow; }
  }

  // Tooltip Arrow
  &:after {
      content: '';
      background: transparent;
      border: $arrow-size solid transparent;
      border-top-color: $background-color;
      top: 0;
  }

  // Active state
  &:hover, &:focus, &:active {
      &:before, &:after {
          opacity: 1
      }
  }

  @if $load-styles == true {
      // ----------------------------------------------------
      // Extra Styles :
      font-size: $font-xs;
      font-weight: 600;

      // Multi-line tooltip (.tooltip-multiline)
      &.tooltip-multiline {
          &:before {
              width: 100vw;
              max-width: $multiline-width;
              white-space: normal;
          }
      }

      // ----------
      // Bottom tooltip (.tooltip-bottom, *-left, *-right )
      &[class*="tooltip-bottom"] {
          &:before, &:after { transform: translate(-50%, $vertical-shift) }
          &:before {
              bottom: auto;
              top: 100%;
          }
          &:after {
              bottom: 0;
              border: $arrow-size solid transparent;
              border-bottom-color: $background-color;
          }
      }
      &.tooltip-bottom-left:before {
          transform: translate(-($arrow-size*3), $vertical-shift);
      }
      &.tooltip-bottom-right:before {
          left: auto;
          right: 50%;
          transform: translate($arrow-size*3, $vertical-shift);
      }

      // ----------
      // Top tooltip (.tooltip-top, *-left, *-right )
      // .tooltip-top not needed (default style)
      &.tooltip-top-left:before {
          transform: translate(-($arrow-size*3), -$vertical-shift);
      }
      &.tooltip-top-right:before {
          left: auto;
          right: 50%;
          transform: translate( $arrow-size*3, -$vertical-shift);
      }
      // End extra styles
      // ----------------------------------------------------
  }
}