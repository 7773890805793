.c-whositfor {
  .c-landing__category {
    color: $pale-red;
  }

  .c-whyneerme-benefits {
    background-color: $white;
    color: $slate-gray;
  }
}

.c-whositfor-hero {
  background-color: $light-200;
  color: $slate-gray;

  @include mq-desktop {
    padding-top: $layout-08;
    display: flex;
    align-items: center;
  }

  @include mq-tablet {
    padding-top: $layout-08;
    display: flex;
    align-items: center;
  }

  @include mq-mobile {
    display: block;
    padding-top: 8rem;
    text-align: center;  
  }
}

.c-whositfor-hero__wrapper, .c-whositfor-hero__video {
  @include mq-desktop {
    width: 50%;
  }

  @include mq-tablet {
    width: 50%;
  }

  @include mq-mobile {
    width: 100%;
  }
}

.c-whositfor-hero__wrapper {
  @include mq-desktop {
    padding: $layout-01 5%;
  }

  @include mq-tablet {
    padding: $layout-01 5%;
  }

  @include mq-mobile {
    padding: $layout-06 $space-04;
  }
  
}

.c-whositfor-hero__title {
  font-weight: 700;
  margin-bottom: $space-05;

  @include mq-desktop {
    font-size: $font-xxl;
  }

  @include mq-tablet {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-lg;
  }
}

.c-whositfor-hero__video {
  @include mq-desktop {
    height: 95vh;
    margin-top: $layout-11 * -1;
    overflow-x: hidden;
    z-index: 2;
  }

  @include mq-tablet {
    height: 95vh;
    margin-top: $layout-11 * -1;
    overflow-x: hidden;
    z-index: 2;
  }

  @include mq-mobile {
    height: auto;
  }

  video {
    @include mq-desktop {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }

    @include mq-tablet {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }

    @include mq-mobile {
      width: 100%;
      display: block;
    }
  }
}

.c-whositfor__benefits {
  margin-top: $layout-06;

  & + p {
    margin-top: 0;
  }
}

.c-whositfor__block {
  padding-right: $space-05;
  padding-left: $space-05;
  display: flex;
  justify-content: center;
  background-color: $pistachio;
  color: $slate-gray;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }

  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-whositfor__block-wrapper {
  max-width: 75rem;
  text-align: center;
  
  h1 {
    font-weight: 400;
    
    @include mq-desktop {
      font-size: $font-xxl;
    }

    @include mq-mobile {
      font-size: $font-lg;
    }
  }
}


.c-whositfor__block-emoji {
  @include mq-desktop {
    font-size: 6rem
  }

  @include mq-mobile {
    font-size: $font-XXX;
  }
}
