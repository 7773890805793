.c-landing.is-about {
  background-color: $light-300;
}

.c-about__hero {
  background-color: $purple;

  @include mq-desktop {
    padding-top: $layout-08;
    display: flex;
    align-items: center;
  }

  @include mq-tablet {
    padding-top: $layout-08;
    display: flex;
    align-items: center;
  }

  @include mq-mobile {
    display: block;
    padding-top: $layout-04;
    text-align: center;
    
  }
}

.c-about-hero__wrapper, .c-about-hero__video {
  @include mq-desktop {
    width: 50%;
  }

  @include mq-tablet {
    width: 50%;
  }

  @include mq-mobile {
    width: 100%;
  }
}

.c-about-hero__wrapper {
  @include mq-desktop {
    padding: $layout-01 6.5%;
  }

  @include mq-tablet {
    padding: $layout-01 5%;
  }

  @include mq-mobile {
    padding: $layout-06 $space-04;
  }
}

.c-about-hero__title {
  color: $pale-red;
}

.c-about-hero__video {
  @include mq-desktop {
    height: 95vh;
    overflow-x: hidden;
    margin-top: $layout-11 * -1;
    z-index: 2;
  }

  @include mq-tablet {
    height: 95vh;
    overflow-x: hidden;
    margin-top: $layout-11 * -1;
    z-index: 2;
  }

  @include mq-mobile {
    height: auto;
  }

  video {
    @include mq-desktop {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }

    @include mq-tablet {
      height: 100%;
      position: absolute;
      left: 50%;
      @include translateX(-50%);
    }

    @include mq-mobile {
      width: 100%;
      display: block;
    }
  }
}

.c-about__intro {
  background-color: $black;
  padding-right: $space-05;
  padding-left: $space-05;
  display: flex;
  justify-content: center;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11 * 2;
  }

  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-about-intro__wrapper {
  max-width: 80rem;
  display: flex;
  flex-wrap: wrap;

  h1 {
    flex: 0 0 100%;

    @include mq-desktop {
      padding-right: $space-04;
      padding-left: $space-04;
    }

    @include mq-mobile {
      font-size: $font-lg;
    } 
  }
}

.c-about-intro__column {
  @include mq-desktop {
    width: calc(100% / 2);
    padding: $space-04;
  }

  @include mq-tablet {
    width: calc(100% / 2);
    font-size: $font-md;
  }

  @include mq-mobile {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  p {
    @include mq-desktop {
      font-size: $font-md;
    }
  }
}

.c-about__phrase {
  background-color: $pistachio;
  padding-top: $layout-11;
  padding-bottom: $layout-11;
  display: flex;
  justify-content: center;
}

.c-about-phrase__wrapper {
  max-width: 70rem;
  text-align: center;
}

.c-about-phrase__title {
  font-family: $font-aktiv-ext;
  color: $slate-gray;
  margin-bottom: $space-07;
  margin-right: $space-04;
  margin-left: $space-04;

  @include mq-desktop {
    font-size: $font-xl;
  }

  @include mq-tablet {
    font-size: $font-lg;
  }

  @include mq-mobile {
    font-size: $font-md;
  }
}

.c-about-stories {
  p {
    @include mq-desktop {
      font-size: $font-md;
    }
  }
}

.c-about-stories__figure {
  width: 100%;
  border-radius: $radius-md;
  overflow: hidden;
  margin-top: $space-06;
  margin-bottom: $space-06;

  img {
    width: 100%;
    display: block;
  }
}

// .c-about-hero__wrapper {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: $dark-500;
//   overflow: hidden;
//   height: 90vh;
//   min-height: 50rem;

//   &:after {
//     content: '';
//     width: 100%;
//     height: 100%;
//     top: 0; 
//     left: 0;
//     position: absolute;
//     background: rgba(0,0,0,.25);
//   }
// }

// .c-about-hero__video {  
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   @include translate(-50%,-50%);

//   @include mq-desktop {
//     width: 100%;
//     height: auto;
//   }

//   @include mq-tablet {
//     height: 100%;
//     width: auto;
//   }

//   @include mq-mobile {
//     height: 100%;
//     width: auto;
//   }
// }

// .c-about-hero {
//   text-align: center;
//   width: 100%;
//   max-width: 80rem;
//   padding-left: $space-05;
//   padding-right: $space-05;
//   z-index: 1;

//   .o-button--lg {
//     margin-top: $layout-02;
//     display: inline-flex;
//   }

//   .c-icon {
//     @include mq-mobile {
//       width: 18rem;
//     }
//   }

//   p {
//     @include mq-desktop {
//       font-size: $font-lg;
//     }

//     @include mq-mobile {
//       font-size: $font-md;
//     }
//   }
// }

// .c-about__logo {
//   color: $pale-red;
//   margin-bottom: $layout-05;

//   .neerme-bar {
//     animation: bounce 15s ease-in-out infinite;
//   }
// }

// .c-about-hero__title {
//   font-family: $font-aktiv-ext;
//   font-weight: 900;
//   margin-bottom: 0;

//   @include mq-desktop {
//     font-size: $font-xxl;
//   }

//   @include mq-mobile {
//     font-size: $font-xl;
//   }
// }


// /* SLOGAN */

// .c-about-slogan__wrapper {
//   background-color: $purple;
//   color: $pistachio;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @include mq-desktop {
//     height: 60rem;
//   }

//   @include mq-mobile {
//     height: 40rem;
//   }
// }

// .c-about-slogan {
//   font-family: $font-aktiv-ext;
//   font-weight: 400;
//   text-align: center;

//   @include mq-desktop {
//     font-size: $font-XXX;
//     width: 50%;
//     margin-bottom: $layout-08;
//   }

//   @include mq-tablet {
//     width: 80%;
//   }

//   @include mq-mobile {
//     font-size: $font-xxl;
//     width: 90%;
//   }
// }


// /* SECTION */

// .c-about__video {
//   display: block;

//   @include mq-desktop {
//     width: 80%;
//   }

//   @include mq-tablet {
//     width: 80%;
//   }

//   @include mq-mobile {
//     right: 0;
//     min-width: 100%;
//     height: 100%;
//     max-height:42rem;
//     position: absolute;
//   }
// }

// .c-about__block {
//   @include mq-desktop {
//     position: absolute;
//     max-width: 45rem;
//     padding: $layout-08 $layout-07;
//   }

//   @include mq-small-desktop {
//     position: absolute;
//     max-width: 45rem;
//     padding: $layout-06 $layout-05;
//   }
//   @include mq-tablet {
//     position: absolute;
//     max-width: 42rem;
//     padding: $layout-05 $layout-04;
//   }
//   @include mq-mobile {
//     width: 90%;
//     padding: $layout-03 $layout-02;
//   }
// }

// .c-about__title {
//   margin-bottom: 0;

//   @include mq-desktop {
//     font-size: $font-xl;
//   }

//   @include mq-tablet {
//     font-size: $font-lg;
//   }

//   @include mq-mobile {
//     font-size: $font-md;
//     line-height: 1.6;
//   }
// }

// .c-about__legend {
//   position: absolute;
//   background-color: $white;
//   color: $slate-gray;
//   display: block;
//   padding: $space-03 $space-04;
// }


// /* INTRO */

// .c-about__intro {
//   @include mq-desktop {
//     margin-bottom: $layout-08;
//   }

//   @include mq-tablet {
//     margin-bottom: $layout-08;
//   }

//   @include mq-mobile {
//     margin-bottom: $layout-06;
//   }

//   .c-about__video {
//     @include mq-desktop {
//       left: -10%;
//       top: -8rem;
//     }

//     @include mq-small-desktop {
//       left: -20%;
//       top: -8rem;
//     }

//     @include mq-tablet {
//       left: -30%;
//       top: -8rem;
//     }

//     @include mq-mobile {
//       left: -10%;
//       width: 100%;
//     }
//   }

//   .c-about__block {
//     background-color: $pistachio;
//     color: $purple;

//     @include mq-desktop {
//       bottom: 5%;
//       right: 3%;
//     }

//     @include mq-tablet {
//       bottom: 5%;
//       right: 3%;
//     }

//     @include mq-mobile {
//       margin: 30rem auto 0;
//     }
//   }

//   .c-about__legend {
//     top: -2rem;

//     @include mq-desktop {
//       right: -2rem;
//     }

//     @include mq-mobile {
//       right: -1rem;
//     }
//   }
// }


// /* POLL */

// .c-about__poll {
//   margin-top: $layout-08;

//   @include mq-desktop {
//     margin-bottom: $layout-08;
//   }

//   @include mq-tablet {
//     margin-bottom: $layout-08;
//   }

//   @include mq-mobile {
//     margin-bottom: $layout-06;
//   }

//   .c-about__video {
//     @include mq-desktop {
//       left: 50%;
//       @include translateX(-50%);
//     }
//   }

//   .c-about__block {
//     background-color: $purple;
//     color: $cream;
    
//     @include mq-desktop {
//       top: 5%;
//       left: 3%;
//     }

//     @include mq-tablet {
//       top: 5%;
//       left: 3%;
//     }

//     @include mq-mobile {
//       margin-top: 35rem;
//     }
//   }

//   .c-about__legend {
//     @include mq-desktop {
//       right: -2rem;
//       bottom: -4rem;
//     }

//     @include mq-mobile {
//       left: 2rem;
//       top: -2rem;
//     }
//   }
// }


// /* EVENTS */ 

// .c-about__events {
//   @include mq-desktop {
//     display: flex;
//     justify-content: flex-end;
//     padding: $layout-08 $layout-04;
//     margin-top: $layout-08;
//   }

//   @include mq-mobile {
//     padding: $layout-06 $layout-01;
//   }

//   ul {
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     width: 100%;

//     @include mq-tablet {
//       margin-top: $layout-08;
//     }

//     @include mq-mobile {
//       margin-top: -2rem;
//     }

//     .o-button--lg {
//       margin-top: $layout-03;
//       text-align: center;

//       @include mq-tablet {
//         flex: 0 0 100%;
//       }

//       @include mq-mobile {
//         flex: 0 0 100%;
//       }
      
//     }

//     li:first-child {
//       @include mq-tablet {
//         display: none;
//       }

//       @include mq-mobile {
//         display: none;
//       }
//     }
//   }

//   .c-about__block {
//     background-color: $white;
//     color: $purple;
    
//     @include mq-desktop {
//       margin-top: -3rem;
//       left: 3%;
//       z-index: 3;
//     }
//   }

//   .c-event-card {
//     cursor: auto;
//     margin: $space-03;

//     @include mq-tablet {
//       width: 47%;
//     }

//     @include mq-mobile {
//       width: calc(50% - 1.6rem);
//     }

//     &:nth-child(9) {
//       .c-event-card__image {
//         background-position-x: 50%;
//       }
//     }
//   }

//   .c-event-card__title {
//     @include mq-mobile {
//       font-size: $font-xxs;
//       font-weight: 700;
//     }
//   }

//   .c-event-card__time span {
//     @include mq-mobile {
//       font-size: $font-xxs;
//     }
//   }
// }


// /* NUMBER */ 

// .c-about-number__wrapper {
//   background-color: $slate-gray;
//   color: $pale-red;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @include mq-desktop {
//     height: 60rem;
//   }

//   @include mq-mobile {
//     height: 40rem;
//   }
// }

// .c-about-number {
//   font-family: $font-aktiv-ext;
//   font-weight: 400;
//   text-align: center;
//   width: 90%;

//   @include mq-desktop {
//     font-size: $font-XXX;
//     margin-bottom: $layout-08;
//   }

//   @include mq-tablet {
//     font-size: $font-XX;
//   }

//   @include mq-mobile {
//     font-size: $font-xl;
//   }

//   strong {
//     font-weight: 900;
//     display: block;
//     line-height: 1.2;

//     @include mq-desktop {
//       font-size: $font-XXX * 2;
//     }

//     @include mq-tablet {
//       font-size: $font-XXX * 1.5;
//     }

//     @include mq-mobile {
//       font-size: $font-X;
//     }
//   }
// }


// /* TYPES */

// .c-about__types {
//   @include mq-desktop {
//     margin-bottom: $layout-08;
//   }

//   @include mq-tablet {
//     margin-bottom: $layout-08;
//   }

//   @include mq-mobile {
//     margin-bottom: $layout-08 * 2;
//   }

//   .c-about__video {
//     @include mq-desktop {
//       margin-left: auto;
//       top: -10rem;
//     }

//     @include mq-mobile {
//       left: -10%;
//       width: 110%;
//     }
//   }

//   .c-about__block {
//     background-color: $pale-red;
//     color: $purple;
//     left: 3%;
    
//     @include mq-desktop {
//       top: 20%;
//     }

//     @include mq-tablet {
//       top: 7%;
//     }

//     @include mq-mobile {
//       margin-top: 20rem;
//       top: 8rem;
//     }
//   }

//   .c-about__title {
//     ul {
//       margin-top: $space-05;
//     }

//     li {
//       list-style-type: disc;
//       list-style-position: inside;
      

//       @include mq-desktop {
//         font-size: $font-lg;
//       }

//       @include mq-tablet {
//         font-size: $font-md;
//       }

//       @include mq-mobile {
//         font-size: $font-bs;
//       }
//     }
//   }
// }



// /* FINISH */

// .c-about-finish__wrapper {
//   padding-right: $space-04;
//   padding-left: $space-04;
//   background-color: $dark-500;
//   display: flex;
//   height: 70vh;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   text-align: center;

//   p {
//     font-size: $font-md;
//   }
// }

// .c-about-finish__title {
//   width: 100%;
//   font-weight: 900;

//   @include mq-desktop {
//     font-size: $font-xxl;
//   }

//   @include mq-mobile {
//     font-size: $font-xl;
//   }
// }

// .c-about-finish {
//   max-width: 80rem;

//   .o-button--lg {
//     margin-top: $layout-02;
//     display: inline-flex;
//   }
// }


// /* ANMATION */

// @keyframes bounce {
//   0% {
//       @include translateX(0);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   3% {
//       @include translateX(20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   9% {
//       @include translateX(-20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   12% {
//       @include translateX(0px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   15% {
//       @include translateX(20px);
//       animation-timing-function: ease-in-out;
//   }
//   33% {
//       @include translateX(20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }

//   36% {
//       @include translateX(-20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   42% {
//       @include translateX(20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   45% {
//       @include translateX(0px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   48% {
//       @include translateX(-20px);
//       animation-timing-function: ease-in-out;
//   }
//   66% {
//       @include translateX(-20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   72% {
//       @include translateX(20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   75% {
//       @include translateX(-20px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   78% {
//       @include translateX(0px);
//       animation-timing-function: cubic-bezier(1,.53,.03,.97);
//   }
//   81% {
//       @include translateX(0px);
//       animation-timing-function: ease-in-out;
//   }
// }