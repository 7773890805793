.c-header {
  width: 100%;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  position: fixed;

  @include mq-mobile {
    padding-left: $space-04;
  }

  @include mq-desktop {
    padding-left: $space-05;
  }

  &.is-solid {
    align-items: center;
    position: absolute;

    @include mq-desktop {
      height: 4.8rem;
    }

    @include mq-tablet {
      height: 4.8rem;
    }

    @include mq-mobile {
      height: 4rem;
    }

    .c-header__links {
      font-family: $font-aktiv-ext;
    }

    .c-header__single {
      font-size: $font-xs;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      height: 4.8rem;
    }

    .c-header__dropdown {
      top: 4.8rem;

      a {
        font-size: $font-xs;
      }
    }
  }

  &.is-gradient {
    background: $dark-500;
    align-items: center;

    @include mq-desktop {
      height: 6.4rem;
    }

    @include mq-tablet {
      height: 6.4rem;
    }

    @include mq-mobile {
      height: 4.8rem;
    }

    .c-header__single {
      font-size: $font-bs;
      font-weight: 400;
      height: 6.4rem;

      @include mq-small-desktop {
        font-size: $font-sm;
      }
    }

    .c-header__dropdown {
      top: 6.4rem;

      a {
        font-size: $font-bs;
      }
    }
  }
}
.c-header__custom-logo {
  flex: 0 0 12rem;
  height: 90%;
  width: auto;
  display: flex;
  align-items: center;
  img {
    width: 7em;
    display: block;
  }
}
.c-header__custom-logo-center {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 90%;
  width: auto;
  display: flex;
  align-items: center;
  img {
    width: 7em;
    display: block;
  }
}
.c-header__logo {
  height: 1.6rem;
  display: flex;
  align-items: center;

  @include mq-desktop {
    margin-right: $space-06;
    flex: 0 0 10rem;
  }

  @include mq-mobile {
    margin-right: $space-03;
    flex: 0 0 3.2rem;
  }

  .is-neerme-logo {
    @include mq-mobile {
      display: none;
    }
  }

  .is-neerme-icon {
    @include mq-desktop {
      display: none;
    }
  }
}

.c-header__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.is-first {
    flex: 1;
    justify-content: flex-start;
  }
}

.c-header__single {
  display: flex;
  align-items: center;

  &.is-language {
    button {
      background: none;
      border: none;
      font-size: $font-xs;
      font-weight: 500;
      cursor: pointer;
      line-height: 1.5;
      display: flex;
    }
  }

  &.is-desktop {
    @include mq-mobile {
      display: none;
    }
    @include mq-tablet {
      display: none;
    }
  }

  &.is-mobile {
    @include mq-desktop {
      display: none;
    }
    @include mq-tablet {
      display: block;
    }

    label {
      font-size: inherit;
    }
  }

  a,
  div,
  label {
    display: flex;
    align-items: center;
    height: 100%;

    @include mq-desktop {
      padding-right: $space-04;
      padding-left: $space-04;
    }

    @include mq-mobile {
      padding-right: $space-03;
      padding-left: $space-03;
    }
  }

  a,
  button {
    color: $light-100;
    text-decoration: none;

    &:hover {
      color: $pistachio;
    }
  }
}

.c-header__icon {
  display: flex;
  align-items: center;

  .c-icon {
    margin-right: $space-03;
  }
}

.c-header__primary {
  cursor: pointer;

  &:hover {
    color: $pistachio;

    & + .c-header__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }

  .c-icon {
    transform: rotate(-90deg);
    margin-left: $space-03;
  }
}

.c-header__dropdown {
  background-color: $dark-500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in;
  position: absolute;
  width: 27rem;
  border-bottom-left-radius: $radius-md;
  border-bottom-right-radius: $radius-md;
  overflow-y: hidden;

  &:hover {
    opacity: 1;
    visibility: visible;
  }

  a {
    display: flex;
    padding: $space-04 $space-05;
    align-items: center;

    &:hover {
      background-color: $pistachio;
      color: $slate-gray;
    }
  }

  span {
    font-size: $font-bs;
    margin-right: $space-02;
  }
}

.c-header__avatar {
  background: lighten($purple, 20%);
  color: $purple;
  border: 1px solid $pistachio;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: $radius-circle;

  .c-icon {
    bottom: -0.3rem;
  }
}

.c-header__ad {
  img {
    width: auto;
    height: 4rem;
    max-height: 4.2rem;
  }
}
