.c-error {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: $dark-400;
  padding: 15% 10%;
  border-radius: $radius-md;

  @include mq-mobile {
    margin-right: $space-04;
    margin-left: $space-04;
  }

  a {
    color: $pistachio;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-error__title {
  font-weight: 500;
  font-size: $font-lg;
  margin-bottom: $space-01;
} 