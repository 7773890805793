.slick-list,.slick-slider,.slick-track {
  position:relative;
  display:block;
}
.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}

.slick-slider{
  box-sizing:border-box;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-touch-callout:none;
  -khtml-user-select:none;
  -ms-touch-action:pan-y;
  touch-action:pan-y;
  -webkit-tap-highlight-color:transparent;
}

.slick-list{overflow:hidden;margin:0;padding:0}

.slick-list:focus{outline:0}

.slick-list.dragging{cursor:pointer;cursor:hand}

.slick-slider .slick-list,.slick-slider .slick-track{
  -webkit-transform:translate3d(0,0,0);
  -moz-transform:translate3d(0,0,0);
  -ms-transform:translate3d(0,0,0);
  -o-transform:translate3d(0,0,0);
  transform:translate3d(0,0,0)
}

.slick-track{top:0;left:0}

.slick-track:after,.slick-track:before {
  display:table;
  content:''
}

.slick-track:after{clear:both}

.slick-slide{
  display:none;
  float:left;
  height:100%;
  min-height:1px;
}

[dir=rtl].slick-slide{float:right}
.slick-slide img{display:block}
.slick-slide.slick-loading img{display:none}
.slick-slide.dragging img{pointer-events:none}
.slick-initialized .slick-slide{display:block}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}
.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */


.slick-dots,.slick-next,.slick-prev{
  position:absolute;
  display:block;
  padding:0
}

.slick-dotted.slick-slider{margin-bottom:30px}
.slick-dots{width:100%;margin:0;list-style:none;text-align:center}
.slick-dots li{cursor:pointer}
.slick-dots li button{
  font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0
}
.slick-dots li button:focus,.slick-dots li button:hover{outline:0}
.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}
.slick-dots li button:before{
  font-size:40px;
  line-height:20px;
  position:absolute;
  top:0;
  left:0;
  width:20px;
  height:20px;
  content:'•';
  text-align:center;
  opacity:.25;
  color:color('dark','400')
}
.slick-dots li.slick-active button:before{opacity:.75;color:#000}