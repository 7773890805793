
.c-event-page__sidebar {
  display: flex;

  @include mq-desktop {
    flex-direction: column;
    flex: 0 0 39%;
    border-left: 1px solid $dark-300;
  }

  @include mq-small-desktop {
    flex-direction: column;
    flex: 0 0 43%;
    border-left: 1px solid $dark-300;
  }

  @include mq-mobile {
    flex-wrap: wrap;
    flex: 0 0 100%;
    flex-direction: row;
  }

  @include mq-tablet {
    flex-wrap: wrap;
    flex: 0 0 100%;
    flex-direction: row;
  }
}

.c-event-page__block {
  @include mq-desktop {
    padding: $layout-05;
    border-bottom: 1px solid $dark-300;
  }

  @include mq-small-desktop {
    padding: $layout-04;
    border-bottom: 1px solid $dark-300;
  }

  @include mq-tablet {
    padding: $layout-03;
  }

  @include mq-mobile {
    padding: $layout-05 $layout-01;
    border-bottom: 1px solid $dark-300;
  }

  &:first-child {
    background-color: $dark-300;
  }

  &:last-child {
    @include mq-desktop {
      border-bottom: none;
    }

    @include mq-tablet {
      border-bottom: 1px solid $dark-300;
    }
  }

  &.is-counter, &.is-access, &.is-buy {
    text-align: center;

    @include mq-tablet {
      flex: 0 0 50%;
      width: 50%;
    }

    @include mq-mobile {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &.is-counter, &.is-buy {
    .o-button--lg {
      display: inline-block;
      margin-top: $space-07;
    }
  }

  &.is-access {
    @include mq-tablet {
      border-left: 1px solid $dark-300;
    }
  }

  &.is-rules {
    flex: 1;

    @include mq-desktop {
      flex: 1;
      width: 100%;
      border-bottom: none;
    }

    @include mq-tablet {
      flex: 0 0 100%;
      border-bottom: 1px solid $dark-300;
    }
  }

  &.is-buy {
    background-color: $dark-200;
  }

  h4 {
    margin-bottom: $space-03;
  }

  ol {
    padding-left: $space-05;
  }

  li {
    list-style-type: decimal;
    margin-bottom: $space-03;
  }
}

.c-event-page__time {
  font-family: $font-proxima;
  font-weight: 800;
  color: $cream;
  margin-bottom: $space-03;

  @include mq-desktop {
    font-size: $font-X;
  }

  @include mq-small-desktop {
    font-size: $font-xxl;
  }

  @include mq-tablet {
    font-size: $font-xxl;
  }

  @include mq-mobile {
    font-size: $font-xxl;
  }
}

.c-event-page__form {
  margin-top: $space-06;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  input {
    @include mq-desktop {
      flex: 1;
    }

    @include mq-small-desktop {
      flex: 0 0 100%;
      order: 1;
    }

    @include mq-tablet {
      flex: 0 0 100%;
      order: 1;
    }

    @include mq-mobile {
      flex: 0 0 100%;
      order: 1;
    }
  }

  .o-button--lg {
    @include mq-desktop {
      max-width: 17rem;
      margin-left: $space-03;
    }

    @include mq-small-desktop {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: $space-07;
      order: 3;
    }

    @include mq-tablet {
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0;
      margin-top: $space-07;
      order: 3;
    }

    @include mq-mobile {
      flex: 0 0 100%;
      margin-left: 0;
      margin-top: $space-04;
      order: 3;
    }
  }

  .o-field__error {
    width: 100%;
    text-align: left;

    @include mq-small-desktop {
      order: 2;
    }

    @include mq-tablet {
      order: 2;
    }

    @include mq-mobile {
      order: 2;
    }
  }
}