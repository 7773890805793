.c-whyneerme-hero {
  background-color: $dark-500;
  @include mq-desktop {
    padding-bottom: $layout-11 * 2;
  }
  
  @include mq-tablet {
    padding-bottom: $layout-11 * 2;
  }

  @include mq-mobile {
    padding-bottom: $layout-10;
  }
}

.c-whyneerme-hero__title {
  color: $cream;
}

.c-whyneerme-hero__wrapper {
  max-width: 75rem;
}

.c-whyneerme-image {
  background: $light-200;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @include mq-desktop {
    padding-bottom: 28.8rem;
  }
  
  @include mq-tablet {
    padding-bottom: 20rem;
  }

  @include mq-mobile {
    padding-bottom: 14rem;
  }
}

.c-whyneerme-image__wrapper {
  max-width: 119rem;
  width: 100%;
  z-index: 1;
  display: block;

  @include mq-desktop {
    margin-top: $layout-11 * -2;
  }
  
  @include mq-tablet {
    margin-top: $layout-11 * -2;
  }

  @include mq-mobile {
    margin-top: $layout-11 * -1;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.c-whyneerme-benefits {
  display: flex;
  justify-content: center;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-whyneerme-benefits__wrapper {
  max-width: 65rem;
  padding-right: $space-04;
  padding-left: $space-04;
}

.c-whyneerme-benefits__list {
  margin-top: $layout-06;
  padding-left: $layout-06;

  li {
    margin-bottom: $space-06;
  }

  h4 {
    margin-bottom: $space-03;
  }

  p {
    margin-top: 0;
  }
}
.c-whyneerme-benefits__emoji {
  font-size: $font-xxl;
  position: absolute;
  top: 0;
  left: $layout-06 * -1;
}


.c-whyneerme-features {
  background-color: $pistachio;
  width: 100%;
  color: $slate-gray;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  @include mq-desktop {
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-tablet{
    padding-top: $layout-11;
    padding-bottom: $layout-11;
  }
  @include mq-mobile {
    padding-top: $layout-08;
    padding-bottom: $layout-08;
  }
}

.c-whyneerme-features__wrapper{
  width: 100%;
  max-width: 75rem;
  padding-right: $space-05;
  padding-left: $space-05;
  margin-bottom: $layout-05;
  text-align: center;
}

.c-whyneerme-features__content {
  @include mq-desktop {
    width: 60%;
    padding-right: 12rem;
    padding-left: 12rem;
  }

  @include mq-small-desktop {
    padding-right: $layout-06;
    padding-left: $layout-06;
  }

  @include mq-tablet {
    width: 100%;
    padding-right: $space-05;
    padding-left: $space-05;
  }

  @include mq-mobile {
    width: 100%;
    padding-right: $space-05;
    padding-left: $space-05;
  }
}

.c-whyneerme-features__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
  }

  li {
    margin-bottom: $space-05;

    @include mq-desktop {
      width: calc(50% - 3.2rem);
    }

    @include mq-tablet {
      width: calc(50% - 3.2rem);
    }

    @include mq-mobile {
      max-width: 40rem;
      width: 100%;
    } 
  }
}

.c-whyneerme-features__emoji {
  font-size: $font-xxl;
}

.c-whyneerme-features__video {
  @include mq-desktop {
    width: 40%;
    overflow-x: hidden;
  }
  @include mq-tablet {
    width: 100%;
    padding-left: $space-04;
    padding-right: $space-04;
  }
  
  @include mq-mobile {
    width: 100%;
    padding-left: $space-04;
    padding-right: $space-04;
  }
  
  video {
    @include mq-desktop {
      height: 60rem;
    }
    @include mq-tablet {
      width: 100%;
      height: auto;
    }
    @include mq-mobile {
      width: 100%;
      height: auto;
    }
  }
}


.c-whyneerme-magnet {
  background-color: $black;
  color: $white;
}