$white: #FFFFFF;
$black: #0F0F15;

/* LIGHTS */
$light-100: #F3F3F6;
$light-200: #E2E2E9;
$light-300: #D5D5DD;
$light-400: #B9B9C6;
$light-500: #9D9DAF;

/* DARKS */
$dark-100: #5F5F81;
$dark-200: #4E4E6A;
$dark-300: #3D3D52;
$dark-400: #2B2B3B;
$dark-500: #1A1A23;

/* PRIMARY */
$purple: #502D53;
$pale-red: #E14C6C;
$cream: #F7D7BA;
$pistachio: #73D4AF;
$slate-gray: $dark-400;

/* ACCENTS */
$yellow: darken($cream, 20%);


/* COMPONENT COLORS */
$border-light: $light-200;
$border-dark: $dark-300;
$text-light: $dark-400;
$text-dark: $white;

$input-color: $dark-300;
$input-background: $light-100;
$input-border: $light-400;

/* ALERTS COLORS */
$alert-success: $pistachio;
$alert-error: $pale-red;
$alert-warning: $yellow;


body {
  color: $white;
  background-color: $dark-500;
}

a {
  color: $pistachio;
}

::selection {
  background: $pistachio;
  color: $white;
  text-shadow: none;
}