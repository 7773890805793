.o-alert {
  width: 100%;
  padding: $space-03 $space-05 $space-03 $space-04;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  margin-top: $space-06;
  margin-bottom: $space-06;
  border-radius: $radius-sm;

  &.is-success {
    color: $pistachio;
    border-color: $pistachio;
  }
  &.is-error {
    color: $pale-red;
    border-color: $pale-red;
  }
  &.is-warning {
    color: $yellow;
    border-color: darken($cream, 20%);
  }

  .c-icon {margin-right: $space-03;}
}

