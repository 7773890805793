
.c-menu__checkbox {
  display: none;

  &:checked + .c-menu {
    opacity: 1;
    visibility: visible;
    z-index: 5; 
    @include animate;
  }
}

.c-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  background-color: rgba($black,0.95);
  padding-bottom: $layout-06;
  overflow-y: scroll;
  @include animate;

  @include mq-desktop {
    display: none;
  }

  @include mq-tablet {
    display: block;
    padding-top: 6.4rem;
  }

  @include mq-mobile {
    display: block;
    padding-top: 4.8rem;
  }

  .c-menu__name,li {
    text-transform: none;
    letter-spacing: 0;
  }
}

.c-menu__links {
  a {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: $space-04;
    text-decoration: none;
    font-size: $font-bs;
    color: $white;

    &:hover, &:active {
      background-color: $pistachio;
      color: $slate-gray;
      text-decoration: none;
    }
  }

  li {
    li a {
      padding-left: $space-06;
      font-weight: 400;
      color: $light-200;
      font-size: $font-sm;
    }
  }

  .is-language {
    display: flex;
    padding: $space-05 $space-04;

    button {
      background: none;
      border: none;
      font-size: $font-sm;
      cursor: pointer;
      line-height: 1.5;
      display: flex;
      color: $white;
      letter-spacing: 2px;

      &:hover {
        background-color: $pistachio;
        color: initial;
      }
    }

    span {
      font-size: $font-bs;
    }
  }

  .c-icon {margin-right: $space-03;}
}

