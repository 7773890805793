.c-loading__container {
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: $layout-06;

  &.is-small {
    height: 100%;
    padding: $layout-01;
  }
}

.c-loading {
  align-self: center;

  svg {height: auto;}
}

.c-loading__title {
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: $space-03;
  font-weight: 500;
}